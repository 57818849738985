import React from "react";
import "./index.css";

const TableLoader = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        height: "400px", // Adjust height to fit table rows
        width: "100%",
      }}
    >
      <div className="spinner-border custom-spinner" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default TableLoader;
