import React from "react";
// import { Tick02Icon } from "../../../../icons/icons";

const ProgressBar = ({steps, currentStep, originalFile, campaingDetails, csvFileName, isManuallyImported, templateType}) => {
  // const percentage = (currentStep / (steps.length - 1)) * 100;
  console.log("campaingDetails ===>",campaingDetails);
  // console.log("currentStep",currentStep);
  // console.log("steps",steps);
  console.log("csvFileName :: ",csvFileName);
  // console.log("isManuallyImported",isManuallyImported);
  console.log("originalFile",originalFile);
  

  return (
    <>
      <div className="grid custom-step-tab mt-3">
        {steps.map((step, index) => {
          // console.log("index",index);      
          return (
            <div className="g-col-12 g-col-md-6 g-col-lg-4 g-col-xl-12">
            <div
              key={index}
              className={`d-flex align-items-center gap-2 step-tab  ${
                index === currentStep ? "cursor-pointer active-step" : null
              } ${index < currentStep ? "completed-step" : null}`}
            >
              <div className="rounded-2 d-flex align-items-center justify-content-center icon">
                {index < currentStep ? (
                  // <Tick02Icon
                  //   width={22}
                  //   height={22}
                  //   color="#A5596A"
                  //   strokeWidth="1.8"
                  // />
                  step.icon
                ) : (
                  step.icon
                )}
              </div>
              <span>
                <h5 className="fw-medium text-capitalize">
                  {step.title}
                    {/* {index === 1
                      ? isManuallyImported
                        ? "Manually Imported"
                        : csvFileName
                        ? csvFileName
                        : step.title
                      : index === 2 && originalFile?.name
                      ? originalFile.name
                      : step.title} */}
                  </h5>
                <small className="text-capitalize">
                {/* {index === 0
                  ? templateType === "pdf"
                    ? "Type: With Document"
                    : templateType === "text"
                    ? "Type: Without Document"
                    : step.subtitle
                  : index === 1
                  ? isManuallyImported
                    ? "Manual Import Selected"
                    : csvFileName 
                    ? `Imported from File: ${csvFileName}` 
                    : campaingDetails?.csv_file_name 
                    ? `Imported from File: ${campaingDetails?.csv_file_name}`
                  : index === 2
                  ? originalFile?.name 
                    ? originalFile.name 
                    : campaingDetails?.original_pdf_name 
                    ? `Original File: ${campaingDetails?.original_pdf_name}`
                    : step.subtitle
                  : step.subtitle} */}
                  {index === 0
                    ? templateType === "pdf"
                      ? "Type: With Document"
                      : templateType === "text"
                      ? "Type: Without Document"
                      : step.subtitle
                    : index === 1
                    ? isManuallyImported
                      ? "Manual Import Selected"
                      : csvFileName
                      ? `Imported from File: ${csvFileName}`
                      : campaingDetails?.csv_file_name
                      ? campaingDetails.csv_file_name
                      : step.subtitle
                    : index === 2
                    ? originalFile?.name
                      ? originalFile.name
                      : campaingDetails?.original_pdf_name
                      ? campaingDetails.original_pdf_name
                      : step.subtitle
                    : step.subtitle}
                  </small>
              </span>
            </div>
          </div>
          )
        })}
      </div>
    </>
  );
};

export default ProgressBar;
