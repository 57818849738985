import React, { useCallback, useEffect, useMemo, useState } from "react";
import CommonLayout from "../../../container/layouts/master/index";
import CampaignsChart from "../../../components/charts/campaigns-chart";
import CampaignsReportChart from "./../../../components/charts/campaigns-report-chart";
import CreditHistoryChart from "./../../../components/charts/credit-history-chart";
import BaisicWidgets from "./../../../components/widget/baisic-widgets/index";
import { fetchBarChartCampaign, fetchCreditChartCampaign, fetchDashboardData, fetchPieChartCampaign } from "../../../store/eventsSlice";
import {Form } from "react-bootstrap";
// import { Tooltip as TT } from "react-bootstrap";
// import { Doughnut } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { useLoader } from "../../../context/LoaderContext";
import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend,ArcElement} from "chart.js";
import moment from "moment";
// import { AlertTriangle } from "react-feather";
import "./dashboard.css";
import {Calendar03Icon,UserGroup03Icon,Coins01Icon,WhatsappIcon,MessageBlockedIcon,CoinsSwapIcon,Coins02Icon,DatabaseIcon,WaterfallUp01Icon,WarningIcon} from "../../../icons/icons";
// import RadioButton from "../../../components/radio-button";
import AlertPopup from "./AlertPopup";
import CardLoader from "../../../components/cardLoader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const { setLoading } = useLoader();
  const dispatch = useDispatch();

  const dashboardData = useSelector((state) => state.events?.dashboardData);
  const expiry_date = useSelector((state) => state.auth?.user?.expiry_date);

  const userCreatedYear = useSelector((state) => state?.auth?.user?.created_at)
  const pieChartData = useSelector((state) => state?.events?.pieChartData?.data);
  const pieChartLoader = useSelector((state) => state?.events?.pieChartLoader)

  const barChartData = useSelector((state) => state?.events?.barChartData?.data);
  const barChartLoader = useSelector((state) => state?.events?.barChartLoader);

  const creditBarData = useSelector((state) => state?.events?.creditChartData?.data)
  const creditBarLoader = useSelector((state) => state?.events?.creditChartLoader)

  // console.log(
    // "barChartData",barChartData
    // "barChartLoader",barChartLoader
    // "dashboardData",dashboardData
    // "creditBarData",creditBarData,
    // "creditBarLoader",creditBarLoader
  // );
  

  // Extract year from `created_at`
  const startYear = userCreatedYear ? new Date(userCreatedYear).getFullYear() : new Date().getFullYear();
  const currentYear = new Date().getFullYear();
  // const numberOfYears = 10; // Change this to 8 if you want up to 8 years
  // const years = Array.from({ length: numberOfYears }, (_, i) => startYear + i);
  // Generate an array of years from startYear to currentYear
  const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i);


  // State to store selected year in Pie Chart
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const handleYearChange = (event) => {
    const newYear = event.target.value;
    setSelectedYear(newYear);
  };

  // State to store selected year in Bar Chart
  const [barSelectedYear,setBarSelectedYear] = useState(currentYear)
  const handleBarYearChange = (event) => {
    const newYear = event.target.value;
    setBarSelectedYear(newYear);
  }

   // State to store selected year in Bar Credit Chart
   const [creditBarSelectedYear,setCreditBarSelectedYear] = useState(currentYear)
   const handleCreditBarYearChange = (event) => {
      const newYear = event.target.value;
      setCreditBarSelectedYear(newYear);
   }
   
 

  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  // Calculate days left
  const daysLeft = useMemo(() => {
    if (!expiry_date) return null;
    const expiry = moment(expiry_date); // Parse expiry date
    const today = moment(); // Get current date
    return expiry.diff(today, "days"); // Difference in days
  }, [expiry_date]);

  // ✅ Memoize the function to avoid re-creation on each render
  const checkAndShowPopup = useCallback(() => {
    const today = new Date().toISOString().split("T")[0]; // Format: YYYY-MM-DD
    const lastPopupDate = localStorage.getItem("lastPopupDate");

    if (
      lastPopupDate !== today &&
      (dashboardData?.available_credits < 100 ||
        (daysLeft !== null && daysLeft <= 30))
    ) {
      setShowModal(true); // Show modal on first login of the day
      localStorage.setItem("lastPopupDate", today); // Save today's date
    }
  }, [dashboardData?.available_credits, daysLeft]); // ✅ Add dependencies here



    // Function to handle closing the modal
    const handleClose = () => setShowModal(false);

  // const daysUntilExpiry = moment(expiry_date).diff(moment(), "days");
 
  useEffect(() => {
    checkAndShowPopup();
  }, [checkAndShowPopup]); // ✅ Use the memoized function as a dependency

  useEffect(() => {
    //  Pie Chart API Call
     dispatch(fetchPieChartCampaign({year:selectedYear}))
  }, [dispatch, selectedYear])

  useEffect(() => {
    //  Bar Chart API Call
    dispatch(fetchBarChartCampaign({year:barSelectedYear}))
  }, [dispatch, barSelectedYear])

  useEffect(() => {
    //  Bar Chart API Call
    dispatch(fetchCreditChartCampaign({year:creditBarSelectedYear}))
  }, [dispatch, creditBarSelectedYear])
  

  const getDashboardData = useCallback(() => {
    // setLoading(true);
    
    dispatch(fetchDashboardData({}))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, setLoading]);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  const titles = [{ name: "Dashboard", path: "/customers" }];

  return (
    <>
      <CommonLayout titles={titles}>
        <div className="dashboard-content">
          <div className="dashboard-header rounded-2 common-shadow bg-white">
            <div className="d-flex align-items-center gap-4 justify-content-between">
              <div className="d-flex align-items-center gap-3">
                <div className="date-icon rounded-1 d-flex align-items-center justify-content-center">
                  <Calendar03Icon
                    height={24}
                    width={24}
                    strokeWidth="1.8"
                    color="#A5596A"
                  />
                </div>
                <div>
                  <p className="text-capitalize">Subscription Expired on</p>
                  <small className="text-capitalize mt-1 fw-medium">
                    {moment(expiry_date).format("DD MMMM, YYYY")}
                  </small>
                </div>
              </div>
              {daysLeft !== null && daysLeft <= 30 && ( // Show warning if less than 30 days left
                <div className="d-flex align-items-center date-text gap-2">
                  <WarningIcon
                    height={16}
                    width={16}
                    strokeWidth="1.8"
                    color="#6d6b77"
                  />
                  <span> Your account will expire soon! {daysLeft} days left.</span>
                </div>
              )}
            </div>
          </div>
          <div className="dashboard-body">
            <div>
              <h4 className="fw-medium">Overview</h4>
              <div className="grid gap-4 row-gap-xxl-0 dashboard-body-overview mt-4">
                <div className="g-col-6 g-col-xxl-2">
                  <div className="grid gap-4 column-gap-0">
                    <div className="g-col-12">
                      <div className="bg-white common-shadow rounded-2 py-1">
                        <BaisicWidgets
                          title="Total Customer"
                          number={dashboardData?.total_customers || 0}
                          icon={
                            <UserGroup03Icon
                              height={48}
                              width={48}
                              color="#F6F5ED"
                              strokeWidth="1.5"
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className="g-col-12">
                      <div className="bg-white common-shadow rounded-2 py-1">
                        <BaisicWidgets
                          title="Connected"
                          number={dashboardData?.total_active_customers || 0}
                          icon={
                            <WhatsappIcon
                              height={48}
                              width={48}
                              color="#EEFEEC"
                              strokeWidth="1.5"
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className="g-col-12">
                      <div className="bg-white common-shadow rounded-2 py-1">
                        <BaisicWidgets
                          title="Not Connected"
                          number={dashboardData?.total_inactive_customers || 0}
                          icon={
                            <MessageBlockedIcon
                              height={48}
                              width={48}
                              color="#FCF0F0"
                              strokeWidth="1.5"
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="g-col-6 g-col-xxl-3">
                  <div className="rounded-2 common-shadow bg-white px-3 py-4">
                 
                  <div className="d-flex align-items-center justify-content-between gap-3">
                      <h5 className="text-capitalize">Campaigns</h5>
                      <div className="custom-year-select">
                        <Form.Select
                          aria-label="Year selection"
                          className="shadow-none"
                          value={selectedYear}
                          onChange={handleYearChange}
                        >
                          {years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </div>
                    {pieChartLoader ? <CardLoader/> :  <CampaignsChart className="mt-3" pieChartData={pieChartData}/>}
                  </div>
                </div> 
        

                <div className="g-col-12 g-col-xxl-7">
                  <div className="rounded-2 bg-white common-shadow grid gap-0">
                    <div className="px-3 py-4 g-col-8 border-end">
                      <h5 className="text-capitalize">Campaigns</h5>
                      {barChartLoader ? 
                       <div
                       className="d-flex justify-content-center align-items-center"
                       style={{
                         height: "294px", // Adjust height to fit table rows
                         width: "100%",
                       }}
                     >
                       <div className="spinner-border custom-spinner" role="status">
                         <span className="visually-hidden">Loading...</span>
                       </div>
                     </div>
                       : <CampaignsReportChart className="mt-3" barChartData={barChartData}/>}
                    </div>
                    <div className="px-3 py-4 g-col-4">
                      <div className="d-flex align-items-center h-100 flex-column justify-content-between gap-3">
                        <div className=" d-flex align-items-center gap-3 justify-content-between flex-wrap">
                          <div className="d-flex flex-column gap-2 align-items-center">
                            {/* <RadioButton
                              name="sendOption"
                              id="sendInvitation"
                              value="invitation"
                              className="md-radio fw-medium w-100"
                              label="By Year"  
                            /> */}
                            {/* <label className="md-radio fw-medium w-100">By Year</label> */}
                            <div className="text-center d-flex flex-nowrap align-items-center gap-2 cursor-pointer total-campaign-text">
                            <h5 className="text-capitalize">By Year</h5>
                          </div>

                             {/* <RadioButton
                              name="sendOption"
                              id="sendInvitation"
                              value="invitation"
                              className="md-radio fw-medium w-100"
                              label="By Month"
                            /> */}
                          </div>
                          <div className="d-flex flex-column gap-2 align-items-center">
                            <div className="custom-year-select w-100">
                              <Form.Select
                                aria-label="Year selection"
                                className="shadow-none text-center"
                                value={barSelectedYear}
                                onChange={handleBarYearChange}
                              >
                                 {years.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                 ))}
                              </Form.Select>
                            </div>
                            {/* <div className="custom-year-select w-100">
                              <Form.Select
                                aria-label="Default select example"
                                className="shadow-none text-center"
                              >
                                <option>Jan</option>
                                <option value="1">Feb</option>
                                <option value="2">March</option>
                                <option value="3">April</option>
                                <option value="4">May</option>
                                <option value="5">June</option>
                              </Form.Select>
                            </div> */}
                          </div>
                        </div>

                        <div className="text-center total-campaign-text">
                          <h4 className="fw-medium purple">{barChartData ? barChartData?.total_campaigns : 0}</h4>
                          <p className="pt-1">Total Campaigns</p>
                        </div>

                        <div className="text-center d-flex align-items-center gap-2 cursor-pointer total-campaign-text">
                          <span className="rounded-circle"></span>
                          <p>Campaigns</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 ">
              <h4 className="fw-medium">My Credits</h4>
              <div className="grid gap-4  row-gap-xxl-0 mt-4">
                <div className="g-col-12 g-col-xxl-5">
                  <div className="grid dashboard-body-overview">
                    <div className="g-col-6 g-col-xl-4 g-col-xxl-6">
                      <div className="bg-white common-shadow h-100 rounded-2">
                        <BaisicWidgets
                          title="Total Credit"
                          number={dashboardData?.total_credits || 0}
                          icon={
                            <Coins01Icon
                              height={48}
                              width={48}
                              color="#EDF5F6"
                              strokeWidth="1.5"
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className="g-col-6 g-col-xl-4 g-col-xxl-6">
                      <div className="bg-white common-shadow h-100 rounded-2">
                        <BaisicWidgets
                          title="Total Used Credits"
                          number={
                            dashboardData?.total_credits -
                              dashboardData?.available_credits || 0
                          }
                          icon={
                            <Coins02Icon
                              height={48}
                              width={48}
                              color="#FEF6EC"
                              strokeWidth="1.5"
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className="g-col-6 g-col-xl-4 g-col-xxl-6">
                      <div className="bg-white common-shadow h-100 rounded-2">
                        <BaisicWidgets
                          title="Reserved Credits"
                          number={dashboardData?.reserved_credits || 0}
                          icon={
                            <CoinsSwapIcon
                              height={48}
                              width={48}
                              color="#EDEFF6"
                              strokeWidth="1.5"
                            />
                          }
                        />
                      </div>
                    </div>

                    <div className="g-col-6 g-col-xl-4 g-col-xxl-6">
                      <div className="bg-white common-shadow h-100 rounded-2">
                        <BaisicWidgets
                          title="Available Credits"
                          number={dashboardData?.available_credits || 0}
                          icon={
                            <DatabaseIcon
                              height={48}
                              width={48}
                              color="#EFF6ED"
                              strokeWidth="1.5"
                            />
                          }
                        />
                      </div>
                    </div>

                    <div className="g-col-6 g-col-xl-4 g-col-xxl-6">
                      <div className="bg-white common-shadow h-100 rounded-2">
                        <BaisicWidgets
                          title="Total Recharge"
                          number={dashboardData?.total_recharge || 0}
                          icon={
                            <UserGroup03Icon
                              height={48}
                              width={48}
                              color="#F4F3FE"
                              strokeWidth="1.5"
                            />
                          }
                        />
                      </div>
                    </div>

                    <div className="g-col-6 g-col-xl-4 g-col-xxl-6">
                      <div className="bg-white common-shadow h-100 rounded-2">
                        <BaisicWidgets
                          title="Recharge Count"
                          number={dashboardData?.total_recharge_count || 0}
                          icon={
                            <WaterfallUp01Icon
                              height={48}
                              width={48}
                              color="#FFF7F0"
                              strokeWidth="1.5"
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="g-col-12 g-col-xxl-7">
                  <div className="rounded-2 bg-white common-shadow grid gap-0">
                    <div className="px-3 py-4 g-col-8 border-end dashboard-body-overview">
                      <h5 className="text-capitalize">Campaigns</h5>
                      {creditBarLoader ?  
                       <div
                       className="d-flex justify-content-center align-items-center"
                       style={{
                         height: "268px", // Adjust height to fit table rows
                         width: "100%",
                       }}
                     >
                       <div className="spinner-border custom-spinner" role="status">
                         <span className="visually-hidden">Loading...</span>
                       </div>
                     </div>
                      : <CreditHistoryChart className="mt-3" creditBarData={creditBarData}/>}
                    </div>
                    <div className="px-3 py-4 g-col-4">
                      <div className="d-flex align-items-center h-100 flex-column justify-content-between gap-3">
                        <div className="custom-year-select">
                            <Form.Select
                              aria-label="Year selection"
                              className="shadow-none text-center"
                              value={creditBarSelectedYear}
                              onChange={handleCreditBarYearChange}
                            >
                                {years.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                                ))}
                            </Form.Select>
                        </div>

                        <div className="d-flex align-items-center flex-column gap-3">
                          <div className="text-center total-campaign-text">
                            <h4 className="fw-medium purple">{creditBarData?.total_credits || 0}</h4>
                            <p className="pt-1">Total Credit</p>
                          </div>
                          <div className="text-center total-campaign-text">
                            <h4 className="fw-medium orange">{creditBarData?.total_used_credits || 0}</h4>
                            <p className="pt-1">Used credit</p>
                          </div>
                        </div>

                        <div className="d-flex flex-wrap align-items-center gap-3">
                          <div className="text-center d-flex flex-nowrap align-items-center gap-2 cursor-pointer total-campaign-text">
                            <span className="rounded-circle"></span>
                            <p white-space-nowrap>Credit</p>
                          </div>
                          <div className="text-center d-flex  flex-nowrap align-items-center gap-2 cursor-pointer total-campaign-text">
                            <span className="rounded-circle orange"></span>
                            <p className=" white-space-nowrap">Used credit</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="grid gap-4 advance-widgets-container">
          <AdvanceWidgets
            icon="1"
            className="bg-white g-col-3"
            value={dashboardData?.total_credits || 0}
            title="Total Credits"
          />
          <AdvanceWidgets
            icon="1"
            className="bg-white g-col-3"
            value={
              dashboardData?.total_credits - dashboardData?.available_credits ||
              0
            }
            title="Total Used Credits"
          />
          <AdvanceWidgets
            icon="1"
            className="bg-white g-col-3"
            value={dashboardData?.reserved_credits || 0}
            title="Total Reserved Credits"
          />
          <AdvanceWidgets
            icon="1"
            className="bg-white g-col-3"
            value={dashboardData?.available_credits || 0}
            title="Available Credits"
          />
          <AdvanceWidgets
            icon="1"
            className="bg-white g-col-3"
            value="Expire On"
            title={moment(expiry_date).format("DD MMMM, YYYY")}
          />
        </div> */}
      </CommonLayout>
      <AlertPopup showModal={showModal} daysLeft={daysLeft} dashboardData={dashboardData} handleClose={handleClose}/>
    </>
  );
};

export default Dashboard;
