import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

export default function CampaignsChart({ className,pieChartData }) {
  // console.log("pieChartData",pieChartData)



  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        type: "donut",
      },
      stroke: {
        width: 0,
      },
      labels: [
        "Completed",
        "In Process",
        "Pending",
        "Scheduled",
        "Drafted",
        "Failed",
        "Cancel",
        "Paused",
        "Partially Failed"
      ],
      colors: [
        "#28C76F", // Green - Completed
        "#FFA500", // Orange - In Process
        "#19D8CE", // Cyan - Pending
        "#F88379", // **Coral Pink - Scheduled**
        "#2BA1F6", // Blue - Drafted
        "#F62B2B", // Red - Failed
        "#A52A2A", // Brown - Cancel
        "#6A5ACD", // Slate Blue - Paused
        "#D9534F"  // Coral Red - Partially Failed
      ],
      plotOptions: {
        pie: {
          donut: {
            size: "72%",
            labels: {
              show: true,
              name: {
                show: true,
                offsetY: -10,
                color: "#28C76F",
                fontSize: "15px",
                fontWeight: "medium",
              },
              value: {
                show: true,
                offsetY: 10,
                fontSize: "20px",
                fontWeight: "medium",
                color: "#444050",
                // formatter: function (val) {
                //   const total = state.series.reduce((a, b) => a + b, 0);
                //   const completedValue = state.series[0];
                //   const percentage = ((completedValue / total) * 100).toFixed(
                //     1
                //   );
                //   return `${percentage}%`;
                // },
              },
              total: {
                show: true,
                label: "Campaigns",
                fontSize: "15px",
                fontWeight: "normal",
                color: "#28C76F",
                // formatter: function () {
                //   const total = state.series.reduce((a, b) => a + b, 0);
                //   const completedValue = state.series[0];
                //   const percentage = ((completedValue / total) * 100).toFixed(
                //     1
                //   );
                //   return `${percentage}%`;
                // },
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "bottom",
        fontSize: "12px", // Default font size for smaller screens
      },
    },
    chartHeight: 260,
  });


  const adjustChartDimensions = () => {
    const screenWidth = window.innerWidth;
    const fontSize = screenWidth > 1920 ? "20px" : "15px";
    const chartHeight = screenWidth > 1920 ? 280 : 260;
    const legendFontSize = screenWidth > 1920 ? "18px" : "12px";

    setState((prevState) => ({
      ...prevState,
      options: {
        ...prevState.options,
        plotOptions: {
          ...prevState.options.plotOptions,
          pie: {
            ...prevState.options.plotOptions.pie,
            donut: {
              ...prevState.options.plotOptions.pie.donut,
              labels: {
                ...prevState.options.plotOptions.pie.donut.labels,
                name: {
                  ...prevState.options.plotOptions.pie.donut.labels.name,
                  fontSize: fontSize,
                },
                value: {
                  ...prevState.options.plotOptions.pie.donut.labels.value,
                  fontSize: fontSize,
                },
                total: {
                  ...prevState.options.plotOptions.pie.donut.labels.total,
                  fontSize: fontSize,
                },
              },
            },
          },
        },
        legend: {
          ...prevState.options.legend,
          fontSize: legendFontSize,
        },
      },
      chartHeight: chartHeight,
    }));
  };

  useEffect(() => {
    if (pieChartData?.status_wise_campaigns) {
      const newSeries = [
        pieChartData.status_wise_campaigns.completed || 0,
        pieChartData.status_wise_campaigns["in-process"] || 0,
        pieChartData.status_wise_campaigns.pending || 0,
        pieChartData.status_wise_campaigns.scheduled || 0,
        pieChartData.status_wise_campaigns.drafted || 0,
        pieChartData.status_wise_campaigns.failed || 0,
        pieChartData.status_wise_campaigns.cancel || 0,
        pieChartData.status_wise_campaigns.paused || 0,
        pieChartData.status_wise_campaigns.partially_failed || 0,
      ];
  
      setState((prevState) => ({
        ...prevState,
        series: newSeries,
        options: {
          ...prevState.options,
          plotOptions: {
            ...prevState.options.plotOptions,
            pie: {
              ...prevState.options.plotOptions.pie,
              donut: {
                ...prevState.options.plotOptions.pie.donut,
                labels: {
                  ...prevState.options.plotOptions.pie.donut.labels,
                  total: {
                    show: true,
                    label: "Campaigns",
                    fontSize: "15px",
                    fontWeight: "normal",
                    color: "#28C76F",
                    formatter: function () {
                      return pieChartData.total_campaigns || 0;
                    },
                  },
                },
              },
            },
          },
        },
      }));
    }
  }, [pieChartData]);
  

  useEffect(() => {
    adjustChartDimensions();
    window.addEventListener("resize", adjustChartDimensions);

    return () => {
      window.removeEventListener("resize", adjustChartDimensions);
    };
  }, []);

  return (
    <div className={className}>
      <div id="chart">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="donut"
          height={state.chartHeight}
        />
      </div>
    </div>
  );
}
