import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"; // Import styles


const PdfViewer = ({ pdfPageWiseView, isOpen, setIsOpen }) => {
    if (!isOpen) return null; // Don't render if not open
  
    return (
      <div className="fullscreen-overlay" onClick={() => setIsOpen(false)}>
        <div className="gallery-container" onClick={(e) => e.stopPropagation()}>
          <ImageGallery
            items={pdfPageWiseView}
            showFullscreenButton={true}
            showPlayButton={true}
            showThumbnails={false}
            showBullets={true}
          />
        </div>
      </div>
    );
  };
  
export default PdfViewer;