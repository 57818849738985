import React, { useEffect, useState } from "react";
import {Container, Button, ToastContainer,Toast, Modal, Form} from "react-bootstrap";
import "./profileModule.css";
import CommonLayer from "../../layouts/master/index";
import { useDispatch, useSelector } from "react-redux";
import { userDetails, userUpdatePassword } from "../../../store/authSlice";
import {Formik } from "formik";
import { editProfileSchema, passwordSchema } from "../../../schema/validationSchemas";
import { fetchCountry } from "../../../store/customerSlice";
import { useLoader } from "../../../context/LoaderContext";
import { Cancel01Icon, CheckmarkBadge04Icon, EyeIcon, ViewOffSlashIcon } from "../../../icons/icons";
import CommomButton from "../../../components/common-button";
import CommonFormLabel from "../../../components/common-form-label";
import Input from "../../../components/input";
import { fetchCityByStateId, fetchStates, organizationType, updateProfile } from "../../../store/eventsSlice";
import SelectBox from "../../../components/select-box";
import ValidationFeedback from "../../../components/validation-feedback";

export default function Detail() {
  // const [value, setValue] = useState("");
  const { setLoading } = useLoader();
  const dispatch = useDispatch();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("toast-success");
  const [show, setShow] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  // Eye Button Visibility
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseChangePassword = () => setShowChangePassword(false);
  const handleShowChangePassword = () => setShowChangePassword(true);

  //   const [name, setName] = useState("");
  //   const [email, setEmail] = useState("");
  //   const [mobileNumber, setMobileNumber] = useState("");
  // const countries = useSelector((state) => state.events.countries);
  const user = useSelector((state) => state.auth.user);

  const states = useSelector((state) => state?.events?.states);
  const organizationTypes = useSelector((state) => state?.events?.orgTypes);
  const [selectedStateId,setSelectedStateId] = useState(user?.state_id ? user?.state_id : "")
  const [cityOptions,setCityOptions] = useState([]);

  // console.log("selectedStateId",selectedStateId);
  // console.log("cityOptions",cityOptions);
  
  
    // console.log('User : ', user);
  // console.log("organizationTYpes",organizationTypes);

    // console.log("states",states);

  const initialProfileUpdate = {
    name: user?.name || "",
    email: user?.email || "",
    city_id: user?.city_id || "",
    state_id: user?.state_id || "",
    mobile_number: user?.mobile_number || "",
    organization_id: user?.organization_id || "",
    organization_name: user?.organization_name || "",
  }
    
  const initialPasswordUpdate = {
    current_password: "",
    new_password: "",
    new_password_confirmation: "",
  };

  useEffect(() => {
    if (selectedStateId){
      dispatch(fetchCityByStateId({state_id:selectedStateId})).then((res) => {
        if(res?.payload){
          const list = res?.payload?.map((item) => ({
            value:item?.id,
            label:item?.name,
          }))
          setCityOptions(list);
        }})}
  }, [dispatch,selectedStateId])
  


  useEffect(() => {
    // setLoading(true);
    dispatch(userDetails()).then((action) => {
      if (action.payload && action.payload.data.success) {
        // console.log("Action : ", action.payload);
        // setName(action.payload.data.data.name);
        // setEmail(action.payload.data.data.email);
        // setMobileNumber(action.payload.data.data.mobile_number);
        // setCountry(action.payload.data.data.country_id);
      }
    });

    dispatch(fetchStates({}))
    dispatch(organizationType({}))

    dispatch(fetchCountry())
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, setLoading]);

  // const getCountryName = (countryId) => {
  //   const country = countries.find((c) => c.id === countryId);
  //   return country ? country.name : "Not Found";
  // };

  const handlePasswordChange = async (values,{ setSubmitting, setErrors, resetForm }) => {
    console.log("Final=values",values);
    dispatch(userUpdatePassword(values))
      .then((action) => {
        if (action.payload && action.payload.data.success) {
          setSnackbarMessage(action.payload.data.message);
          setSnackbarVariant("toast-success");
          setShowSnackbar(true);
          resetForm();
        } else {
          setErrors(action.payload.data);
          setSnackbarMessage(
            action.payload.data.message || "Failed to update password"
          );
          setSnackbarVariant("toast-danger");
          setShowSnackbar(true);
          setShowChangePassword(false);
        }
      })
      .catch((error) => {
        setSnackbarMessage(
          "Update failed: Network or server error" + error.message
        );
        setSnackbarVariant("toast-danger");
        setShowSnackbar(true);
        setShowChangePassword(false);
      })
      .finally(() => {
        setSubmitting(false);
           setShowChangePassword(false);
      });
  };

  const titles = [
    { name: 'Profile', path: '/customers' },
  ];

  const getUserInitial = (name) => {
    return name
      ? name
          .split(" ")
          .map((n) => n.charAt(0))
          .join("")
      : "UN";
  };

  const handleSubmit = async (values, { setSubmitting }) => {
     try{
      setLoading(true);
      setSubmitting(true)
      dispatch(updateProfile(values)).then((action) => {
        if (action?.payload?.data?.success === true){
          setSnackbarMessage(action?.payload?.data?.message || "Profile updated successfully!");
          setSnackbarVariant("toast-success");
          dispatch(userDetails({}));
        } 
      })
     } catch (err){
      const errorMessage = err?.message || "Failed to update a profile!";
      setSnackbarMessage(errorMessage);
      setSnackbarVariant("toast-danger");
      setLoading(false);
     }finally{
      setShowSnackbar(true);
      setSubmitting(false);
      setShow(false); 
      setLoading(false);
     }
  }


  return (
    <CommonLayer titles={titles}>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Container fluid className="px-0">
        {/* If you remove the d-none class from this given row tag, you will see the old design and code. */}
        <div className="profile-content">
          <h4>My Profile</h4>
          <div className="mt-4 pt-1 grid">
            <div className="g-col-6">
              <div className="bg-white rounded-2 p-4">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-2 profile-icon">
                    <div className="icon rounded-circle d-flex align-items-center justify-content-center text-white text-capitalize">{getUserInitial(user.name)}</div>
                    <h6>{user.name}</h6>
                  </div>
                  <Button varient="link" className="bg-transparent border-0 edit-link" onClick={handleShow}>Edit</Button>
                </div>
                <h6 className="mt-4">Personal Details</h6>
                <div className="mt-4 grid gap-3">
                  <div className="g-col-6">
                    <h6>Email:</h6>
                    <p>{user.email}</p>
                  </div>
                  <div className="g-col-6">
                    <h6>Phone:</h6>
                    <p>{user.mobile_number}</p>
                  </div>
                  <div className="g-col-6">
                    <h6>Organization:</h6>
                    <p>{user?.organization_name}</p>
                  </div>
                  <div className="g-col-6">
                    <h6>Organization Type:</h6>
                    <p>{user?.organization_type?.name}</p>
                  </div>
                  <div className="g-col-6">
                    <h6>State:</h6>
                    <p>{user?.state?.name}</p>
                  </div>
                  <div className="g-col-6">
                    <h6>City:</h6>
                    <p>{user?.city?.name}</p>
                  </div>
                  <div className="g-col-6">
                    {/* <CommomButton
                      title="Change Password"
                      onClick={handleShowChangePassword}
                      className="btn-primary common-one-shadow"
                    /> */}
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                 <Button variant="link" type="button" className="bg-transparent border-0 edit-link" onClick={handleShowChangePassword}>Change Password</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

     {/* Modal for Edit Profile Starts */}
      <Modal show={show} onHide={handleClose}
        size="lg"
        centered
        dialogClassName="common-popup-dialog common-popup-dialog-size"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop">

        <Modal.Body className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center  common-popup-colose-btn"
            onClick={handleClose}
          >
            <Cancel01Icon width={16} height={16} color="#BCBBC1" strokeWidth="2.5"/>
          </div>
          <div className="common-mb-space text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">Edit Profile</h4>
            <p>Edit {user.name} Profile</p>
          </div>
          <Formik 
          initialValues={initialProfileUpdate} 
          onSubmit={handleSubmit}
          validationSchema={editProfileSchema}
          >
          {({
            errors,
            touched,
            isSubmitting,
            handleChange,
            values,
            setFieldValue,
            handleSubmit,
          }) => (
        <>
        {/* {console.log("values",values)} */}
        <Form className="grid" onSubmit={handleSubmit}>
            <div className="g-col-6">
              <CommonFormLabel htmlFor="name" title="Name" />
              <Input
                name="name"
                type="text"
                value={values.name}
                placeholder="Enter Your Name"
                onChange={handleChange}
                className={`shadow-none ${errors.name && touched.name ? "error" : "correct"}`}
              />
              {errors.name && touched.name ? (
                <ValidationFeedback title={errors.name} />
              ) : null}
            </div>
            <div className="g-col-6">
              <CommonFormLabel htmlFor="email" title="E-mail" />
              <Input
                name="email"
                type="email"
                value={values?.email}
                placeholder="Enter Your E-mail"
                onChange={handleChange}
                className={`shadow-none ${errors.email && touched.email ? "error" : "correct"}`}
              />
              {errors?.email && touched?.email ? (
                <ValidationFeedback title={errors?.email} />
              ) : null}
            </div>
            <div className="g-col-6">
              <CommonFormLabel htmlFor="mobile_number" title="Phone No." />
              <Input
                name="mobile_number"
                type="number"
                value={values.mobile_number}
                placeholder="Enter Your Phone No."
                onChange={handleChange}
                className={`shadow-none ${errors.name && touched.name ? "error" : "correct"}`}
              />
              {errors.mobile_number && touched.mobile_number ? (
                <ValidationFeedback title={errors.mobile_number} />
              ) : null}
            </div>
            <div className="g-col-6">
              <CommonFormLabel htmlFor="organization_name" title="Organization" />
              <Input
                name="organization_name"
                type="text"
                value={values.organization_name}
                placeholder="Enter Your Organization"
                onChange={handleChange}
                className={`shadow-none ${errors.organization_name && touched.organization_name ? "error" : "correct"}`}
              />
              {errors.organization_name && touched.organization_name ? (
                <ValidationFeedback title={errors.organization_name} />
              ) : null}
            </div>
            <div className="g-col-6">
              <CommonFormLabel htmlFor="organization_id" title="Organization Type" />
               <SelectBox
                name="organization_id"
                value={values?.organization_id}
                staticOption="Select an Organization Type"
                data={organizationTypes?.map((state) => ({
                  value: state.id,
                  label: state.name,
                }))}
                onChange={(e) => {
                  setFieldValue("organization_id",e?.target?.value);
                }}
              />
                {errors.organization_id && touched.organization_id ? (
                <ValidationFeedback title={errors.organization_id} />
              ) : null}
            </div>
            <div className="g-col-6">
              <CommonFormLabel htmlFor="state_id" title="State" />
              <SelectBox
                name="state_id"
                value={values?.state_id}
                staticOption="Select a State"
                className={`shadow-none ${errors.state && touched.state ? "error" : "correct"}`}
                data={states?.map((state) => ({
                  value: state.id,
                  label: state.name,
                }))}
                onChange={(e) => {
                  setFieldValue("state_id",e.target?.value);
                  setSelectedStateId(e.target.value);
                }}
              />
              {errors.state_id && touched.state_id ? (
                <ValidationFeedback title={errors.state_id} />
              ) : null}
            </div>
            <div className="g-col-6">
              <CommonFormLabel htmlFor="city_id" title="City" />
              <SelectBox
                name="city_id"
                value={values?.city_id}
                staticOption="Select a City"
                data={cityOptions}
                disabled={!selectedStateId} 
                onChange={(e) => {
                  setFieldValue("city_id",e?.target?.value);
                }}
              />
              {errors.city_id && touched.city_id ? (
                <ValidationFeedback title={errors.city_id} />
              ) : null}
            </div>

            <div className="g-col-12 mt-3">
              <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn">
                <CommomButton
                  title="Update"
                  type="submit"
                  disabled={isSubmitting}
                  className="btn-primary primary-shadow"
                />
                <CommomButton
                  title="Cancel"
                  type="button"
                  onClick={handleClose}
                  className="btn-muted"
                />
              </div>
            </div>
        </Form>
        </>
          )}
          </Formik>
        </Modal.Body>
      </Modal>
      {/* Modal for Edit Profile Ends */}

      {/* Modal for Change Password Starts */}
      <Modal show={showChangePassword} onHide={handleCloseChangePassword}
        centered
        dialogClassName="common-popup-dialog common-popup-dialog-size"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop">

        <Modal.Body className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center  common-popup-colose-btn"
            onClick={handleCloseChangePassword}
          >
            <Cancel01Icon
              width={16}
              height={16}
              color="#BCBBC1"
              strokeWidth="2.5"
            />
          </div>
          <div className="common-mb-space text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">Change Password</h4>
            <p>Change Password {user.name} Profile</p>
          </div>
          <Formik
            onSubmit={handlePasswordChange}
            validationSchema={passwordSchema}
            initialValues={initialPasswordUpdate}
            >
            {({errors,touched,isSubmitting,handleChange,values,handleSubmit}) => (
              <>
              {console.log("Errors",errors)}
              {console.log("values",values)}
               <Form className="grid gap-3" onSubmit={handleSubmit}>
                  <div className="g-col-12">
                    <CommonFormLabel htmlFor="current_password" title="Current Password" />
                    <div className="position-relative">
                    <Input
                      name="current_password"
                      type={showCurrentPassword ? "text" : "password"}
                      value={values.current_password}
                      onChange={handleChange}
                      placeholder="Enter Your Current Password"
                    className={`shadow-none ${errors.current_password && touched.current_password ? "error" : "correct"
                      }`}
                    />
                      <div
                        className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                        onClick={() => setShowCurrentPassword(!showCurrentPassword)} 
                      >
                        {showCurrentPassword ? (
                          <EyeIcon
                            width={20}
                            height={20}
                            color="#444050"
                            strokeWidth="1.5"
                            className={`${
                              touched.password && errors.password ? "error" : "correct"
                            }`}
                          />
                        ) : (
                          <ViewOffSlashIcon
                            width={20}
                            height={20}
                            color="#444050"
                            strokeWidth="1.5"
                            className={`${
                              touched.password && errors.password ? "error" : "correct"
                            }`}
                          />
                        )}
                      </div>
                    </div>
                    {errors.current_password && touched.current_password ? (
                      <ValidationFeedback title={errors.current_password} />
                    ) : null}
                  </div>
                  <div className="g-col-12">
                    <CommonFormLabel htmlFor="new_password" title="New Password" />
                    <div className="position-relative">
                    <Input
                      name="new_password"
                      type={showNewPassword ? "text" : "password"}
                      value={values.new_password}
                      onChange={handleChange}
                      placeholder="Enter Your New Password"
                    className={`shadow-none ${errors.new_password && touched.new_password ? "error" : "correct"
                      }`}
                    />
                     <div
                        className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                        onClick={() => setShowNewPassword(!showNewPassword)} 
                      >
                        {showNewPassword ? (
                          <EyeIcon
                            width={20}
                            height={20}
                            color="#444050"
                            strokeWidth="1.5"
                            className={`${
                              touched.password && errors.password ? "error" : "correct"
                            }`}
                          />
                        ) : (
                          <ViewOffSlashIcon
                            width={20}
                            height={20}
                            color="#444050"
                            strokeWidth="1.5"
                            className={`${
                              touched.password && errors.password ? "error" : "correct"
                            }`}
                          />
                        )}
                      </div>
                    </div>
                    {errors.new_password && touched.new_password ? (
                      <ValidationFeedback title={errors.new_password} />
                    ) : null}
                  </div>
                  <div className="g-col-12">
                    <CommonFormLabel htmlFor="new_password_confirmation" title="Confirm Password" />
                    <div className="position-relative">
                    <Input
                      name="new_password_confirmation"
                      type={showConfirmPassword ? "text" : "password"}
                      onChange={handleChange}
                      value={values.new_password_confirmation}
                      placeholder="Enter Your Confirm Password"
                    className={`shadow-none ${errors.new_password_confirmation && touched.new_password_confirmation ? "error" : "correct"
                      }`}
                    />
                     <div
                        className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)} 
                      >
                        {showConfirmPassword ? (
                          <EyeIcon
                            width={20}
                            height={20}
                            color="#444050"
                            strokeWidth="1.5"
                            className={`${
                              touched.password && errors.password ? "error" : "correct"
                            }`}
                          />
                        ) : (
                          <ViewOffSlashIcon
                            width={20}
                            height={20}
                            color="#444050"
                            strokeWidth="1.5"
                            className={`${
                              touched.password && errors.password ? "error" : "correct"
                            }`}
                          />
                        )}
                      </div>
                  </div>
                    {errors.new_password_confirmation && touched.new_password_confirmation ? (
                      <ValidationFeedback title={errors.new_password_confirmation} />
                    ) : null}
                  </div>
                  <div className="g-col-12 mt-3">
                    <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn">
                      <CommomButton
                        title="Update"
                        type="submit"
                        disabled={isSubmitting}
                        className="btn-primary primary-shadow"
                      />
                      <CommomButton
                        title="Cancel"
                        type="button"
                        onClick={handleCloseChangePassword}
                        className="btn-muted"
                      />
                    </div>
                  </div>
             </Form>
              </>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {/* Modal for Change Password Ends */}
    </CommonLayer>
  );
}
