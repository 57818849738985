import { DashboardCircleIcon, TaskEdit02Icon, TimeScheduleIcon, Share02Icon, CheckmarkCircle04Icon,
  //  InformationCircleIcon,
    Cancel01Icon} from "../../../icons/icons";

export const CampaignsListTabs = [
  {
    id: "all",
    icon: <DashboardCircleIcon width={16} height={16} color="#444050" strokeWidth="2" />,
    title: "All",
  },
  // {
  //   id: "pending",
  //   icon: <HourglassIcon width={16} height={16} color="#444050" strokeWidth="2" />,
  //   title: "Pending",
  // },
  {
    id: "drafted",
    icon: <TaskEdit02Icon width={16} height={16} color="#444050" strokeWidth="2" />,
    title: "Drafted",
  },
  {
    id: "scheduled",
    icon: <TimeScheduleIcon width={16} height={16} color="#444050" strokeWidth="2" />,
    title: "Scheduled",
  },
  {
    id: "in-process",
    icon: <Share02Icon width={16} height={16} color="#444050" strokeWidth="2" />,
    title: "In-Process",
  },
  {
    id: "completed",
    icon: <CheckmarkCircle04Icon width={16} height={16} color="#444050" strokeWidth="2" />,
    title: "Completed",
  },
  // {
  //   id:"partially_failed",
  //   icon:<InformationCircleIcon  width={16} height={16} color="#444050" strokeWidth="2" />,
  //   title: "Partially Failed",
  // },
  {
    id: "failed",
    icon: <Cancel01Icon width={16} height={16} color="#444050" strokeWidth="2" />,
    title: "Failed",
  },
  {
    id: "cancel",
    icon: <Cancel01Icon width={16} height={16} color="#444050" strokeWidth="2" />,
    title: "Cancelled",
  },
];
