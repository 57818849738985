import React, { useEffect, useState, useCallback } from "react";
import CommomButton from "../../../components/common-button/index";
import NavTabs from "../../../components/nav-tabs/index";
import Input from "../../../components/input/index";
import CommonActionDropDown from "../../../components/common-action-dropdown/index";
import CommonPagination from "../../../components/common-pagination/index";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  Button,
  Toast,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormCheck,
  Card,
  ToastContainer,
  Image,
  ButtonGroup,
} from "react-bootstrap";
import {
  fetchEventDetail,
  addCampaign,
  deleteCampaign,
  cancelCampaign,
  fetchCustomer,
  updateUserCredits,
  fetchAllCampaignDetail,
  clearCampaignDetails,
  clearCustomerCampaignList,
} from "../../../store/eventsSlice";
import { Container, Col, Row } from "react-bootstrap";
// import { MoreVertical } from "react-feather";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
// import SortImage from "../../../sort.svg";
// import PaginationComponent from "../pagination/pagination";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import "./styleModule.css";
import { useLoader } from "../../../context/LoaderContext";
import axios from "axios";
import moment from "moment";
// import { ArrowLeft } from "../../../constants/icons/icons";
import Edit from "../events/Edit";
import {
  WhatsappIcon,
  SmartPhone01Icon,
  RssErrorIcon,
  Add01Icon,
  MoreVerticalIcon,
  CheckmarkBadge04Icon,
  ArrowLeftIcon,
  Delete02Icon,
  Cancel01Icon,
  Megaphone01Icon,
  UserIcon,
  Coins01Icon,
} from "../../../icons/icons";
import { CampaignsDataTabs } from "../../../constants/pages/events/detail/index";
import DeletePopup from "../../../components/delete-popup";
import StatusChip from "../../../components/status-chip";
// import BackPageTab from "../../../components/back-page-tab";
import CreatableSelect from "react-select/creatable";
import { recordsPerPageOptions } from "../campaings/common/Common";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import BackPageBtnTab from "../../../components/back-page-btn-tab";
import useDisableBackButton from "../../../hooks/useDisableBrowserBackBtn";
import Checkbox from "../../../components/checkbox";
import TableLoader from "../../../components/TableLoader";

export default function List({
  editHandler,
  handleOpenModal,
  client_id,
  customerDetailItem,
}) {
  // Use the hook to disable the browser back button
  useDisableBackButton();

  const dispatch = useDispatch();
  const location = useLocation();
  const { setLoading } = useLoader();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("toast-success");
  const campaings = useSelector((state) => state.events?.campaings);
  // const totalPages = useSelector((state) => state.events.totalPages);
  const total = useSelector((state) => state.events.total);

  
  let savePage = sessionStorage.getItem("customerDetailPage");
  let final = parseInt(savePage,10);

  let savedLimit = sessionStorage.getItem("customerDetailLimit");
  let finalLimit = JSON.parse(savedLimit)
  // console.log("cutomer-Detail",final,finalLimit);
  

  const [currentPage, setCurrentPage] = useState(final ? final : 0);
  const [limit, setLimit] = useState(finalLimit ? finalLimit : { value: 10, label: 10 });
  // console.log("detail",limit,currentPage);
  
  const [searchTerm, setSearchTerm] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [showConnentModal, setShowConnectModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [activeTab, setActiveTab] = useState("all");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const [isScanSuccessful, setIsScanSuccessful] = useState(false);
  const [tableLoading,setTableLoading] = useState(false);
  // const [ws, setWs] = useState(null);

  const [showDisconnectConfirmModal, setShowDisconnectConfirmModal] =
    useState(false);
  // const customer_id = useSelector((state) => state.auth.user.id);

  const [qrCode, setQrCode] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);

  const [openDropdown, setOpenDropdown] = useState(null);

  // const [openDeleteCustomerModal,setOpenDeleteCustomerModal] = useState(false)
  // const [customerId,setCustomerId] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();
  const event = useSelector((state) => state.events.event);

  const customerDetailCampaignListLoader = useSelector((state) => state?.events?.loader)
  // console.log("loader",customerDetailCampaignListLoader);
  console.log("activeTab",activeTab,"tableLoading==>",tableLoading);

  

  // const singleEvent = useSelector((state) => state?.events?.events)

  const currentPgPassedInCustomerDetial = location?.state?.currentPage || 0;
  const currentLimitPassedInCustomerDetial = location?.state?.limit || { value: 10, label: 10 };

  // console.log("currentPgPassedInCustomerDetial",currentPgPassedInCustomerDetial);
  console.log("campaings",campaings);
  
  const initialCampaignState = {
    name: "",
    sendOption: "invitation", // Default selection for radio button
  };

  const campaignValidation = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    sendOption: Yup.string().required("Send option is required"),
  });

  const getEventsList = useCallback(() => {
    // setLoading(true);
    dispatch(fetchCustomer({ id: client_id }))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch,setLoading, client_id]);

  useEffect(() => {
    getEventsList();
  }, [getEventsList]);

 
//  MAIN Api for campaingn listing in customer Detail
  const getEventsDetailsData = useCallback(() => {
    // setLoading(true);
    sessionStorage.removeItem('customerDetailLimit');
    sessionStorage.removeItem('customerDetailPage');
    dispatch(clearCustomerCampaignList());

    dispatch(
      fetchEventDetail({
        offset: currentPage,
        limit: limit?.value,
        // search: searchTerm,
        id: client_id,
        status: activeTab,
      })
    )
      .then(() => {
        setLoading(false);
        // setTableLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        // setLoading(false);
      });
  }, [dispatch, currentPage, limit?.value, client_id, setLoading, activeTab]);

  useEffect(() => {
    getEventsDetailsData();
  }, [getEventsDetailsData]);

  // API for search
  useEffect(() => {
    if (searchTerm.trim() === "") {
      // 🚨 If search is cleared, reset to full listing
      getEventsDetailsData();
      return;
    }
    const handler = setTimeout(() => {
      dispatch(fetchEventDetail({ id: client_id, search: searchTerm, offset: currentPage, limit: limit?.value, status: activeTab }));
    }, 2000);

    return () => {
      clearTimeout(handler);
    };
  }, [dispatch,getEventsDetailsData, client_id, searchTerm, currentPage, limit?.value, activeTab]);

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
    // setSelectedRows([]); // Deselect all
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleConnect = async (mobile_number) => {
    try {
      setIsScanSuccessful(!isScanSuccessful);
      const response = await axios.post(
        `${process.env.REACT_APP_WHATSAPP_API_URL}/api/whatsapp/connect`,
        {
          clientId: id,
          wsId: `ws_connect_${id}`,
          userId: id,
        }
      );

      // console.log("Response ::::: ", response);
      // if (response.data.success) {
      //   // Start polling for QR code every 2 seconds
      //   const intervalId = setInterval(async () => {
      //     try {
      //       const response_qr = await axios.post(
      //         `${process.env.REACT_APP_WHATSAPP_API_URL}/api/whatsapp/qr`,
      //         {
      //           clientId: id,
      //           wsId: `ws_connect_${id}`,
      //           userId: id,
      //         }
      //       );

      //       console.log("Qr :::::::", response_qr);

      //       // Check if QR code is available
      //       if (response_qr.data.qrcode) {
      //         setQrCode(response_qr.data.qrcode);
      //         clearInterval(intervalId); // Stop polling once we have the QR code
      //       }
      //     } catch (error) {
      //       console.error("Error fetching QR Code:", error);
      //     }
      //   }, 2000); // Poll every 2 seconds
      // }
      setQrCode(response.data.qrString);
    } catch (error) {
      console.error("Error connecting to WhatsApp:", error);
      setSnackbarMessage("Failed to connect to WhatsApp");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
      setIsScanSuccessful(false);
    }
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
    >
      {children}
    </Link>
  ));

  CustomToggle.displayName = "CustomToggle";

  // const navigateToDetails = (id) => {
  //   navigate(`/customers/${id}`);
  // };

  // const createCampaign = async () => {
  //   const currentDateTime = moment().format("DD-MM-YYYY hh:mm A");
  //   const defaultCampaignName = `New Campaign - ${currentDateTime}`;

  //   try {
  //     setLoading(true);
  //     // Create a new campaign with default values
  //     const formData = new FormData();
  //     formData.append("client_id", client_id);
  //     formData.append("name", defaultCampaignName);
  //     formData.append("sendOption", "invitation");  // Default to invitation

  //     const response = await dispatch(addCampaign(formData));

  //     console.log("Response ::::::::: ", response);

  //     if (response.payload.success) {
  //       setSnackbarMessage(response.payload.message);
  //       setSnackbarVariant("toast-success");
  //       setShowSnackbar(true);
  //       // Redirect to the first step of the campaign creation process
  //       navigate(`/customers/${client_id}/add-campaign/${response.payload.data.id}`);
  //     } else {
  //       setSnackbarMessage(response.payload.message);
  //       setSnackbarVariant("toast-danger");
  //       setShowSnackbar(true);
  //     }
  //   } catch (error) {
  //     setSnackbarMessage("Failed to create campaign");
  //     setSnackbarVariant("toast-danger");
  //     setShowSnackbar(true);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleAddClick = () => {
    // setAddModal(true);
    // if (!event?.whatsapp_client?.is_active) {
    //   setCurrentStep(1);
    // } else {
    //   setCurrentStep(2);
    // }
    // navigate(
    //   `/customers/${client_id}/add-campaign/${id}`
    // );
    dispatch(clearCampaignDetails());
    if (!event?.whatsapp_client?.is_active) {
      setShowConnectModal(true);
    } else {
      navigate(`/customers/new-campaign?client=${client_id}`);
    }
  };

  // const handleAddClick = async () => {
  //   if (event?.whatsapp_client?.is_active) {
  //     // WhatsApp is connected, proceed with campaign creation
  //     createCampaign();
  //   } else {
  //     // WhatsApp is not connected, show the connection modal
  //     setShowConnectModal(true);
  //   }
  // };

  // const handleAddClick = async () => {
  //   if (event?.whatsapp_client?.is_active) {
  //     // WhatsApp is connected, proceed with campaign creation
  //     createCampaign();
  //   } else {
  //     // WhatsApp is not connected, show the connection modal
  //     setShowConnectModal(true);
  //   }
  // };

  const handleOpenConnectModal = () => {
    setShowConnectModal(true);
  };

  const handleCloseConnectModal = useCallback(() => {
    setShowConnectModal(false);
    setQrCode("");
    getEventsDetailsData();
    getEventsList();
  }, [getEventsDetailsData, getEventsList]);

  const handleCloseClickConnectModal = useCallback(() => {
    setShowConnectModal(false);
    setQrCode("");
    setIsScanSuccessful(false);
    // getEventsDetailsData();
    // getEventsList();
  }, []);

  const toggleAddModal = () => {
    setQrCode("");
    setIsScanSuccessful(false);
    setAddModal(!addModal);
    setCurrentStep(1);
  };

  const handleSendMessageClick = (id) => {
    navigate(`/customers/update-campaign/${id}`);
  };

  useEffect(() => {
    const socket = new WebSocket(process.env.REACT_APP_SOCKET_URL);
    socket.onopen = () => {
      // console.log("WebSocket Client Connected");
      const wsId = `ws_connect_${id}`;
      socket.send(JSON.stringify({ type: "REGISTER", id: wsId }));
      // setWs(socket);
    };

    socket.onmessage = (message) => {
      const data = JSON.parse(message.data);

      // console.log("data.status : ", data);
      if (data.status === "connected") {
        setSnackbarMessage("WhatsApp connected successfully");
        setSnackbarVariant("toast-success");
        setShowSnackbar(true);
        setIsScanSuccessful(true);
        handleCloseConnectModal();
        // setCurrentStep(2);
        // createCampaign()
      } else if (data.status === "disconnected") {
        setSnackbarMessage("Failed to connect to WhatsApp");
        setSnackbarVariant("toast-danger");
        setShowSnackbar(true);
        setIsScanSuccessful(false);
      }
    };

    socket.onclose = () => {
      // console.log("WebSocket Client Disconnected");
    };

    return () => {
      socket.close();
    };
  }, [id, handleCloseConnectModal]);

  const handleDeleteCampaignClick = (campaing) => {
    setSelectedCampaign(campaing); // Set the selected campaign to be deleted
    setShowDeleteConfirmModal(true); // Show the confirmation modal
  };

  const handleCancelScheduleClick = (campaing) => {
    setSelectedCampaign(campaing); // Set the selected campaign to be deleted
    setShowCancelConfirmModal(true); // Show the confirmation modal
  };

  const handleConfirmDelete = async () => {
    try {
      setIsSubmitting(true); // Set loading state
      setLoading(true); // Optional loader if you have one
      dispatch(deleteCampaign({ id: selectedCampaign.id })).then((res) => {
        setSnackbarMessage("Campaign deleted successfully");
        setSnackbarVariant("toast-success");
        setShowSnackbar(true);
        getEventsDetailsData() // Refresh the campaigns list
        // getEventsList(); // Refresh the campaigns list
      })
    } catch (error) {
      setSnackbarMessage("Failed to delete campaign");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
      setLoading(false);
    } finally {
      setIsSubmitting(false);
      setLoading(false); // Optional loader
      setShowDeleteConfirmModal(false);
    }
  };

  const handleConfirmCancel = async () => {
    try {
      setIsSubmitting(true); // Set loading state
      setLoading(true); // Optional loader if you have one
      dispatch(cancelCampaign({ id: selectedCampaign.id })).then((res) => {
        setSnackbarMessage("Campaign Canceled successfully");
        setSnackbarVariant("toast-success");
        setShowSnackbar(true);
        getEventsDetailsData() // Refresh the campaigns list
      })
    } catch (error) {
      setSnackbarMessage("Failed to cancel campaign");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
    } finally {
      setIsSubmitting(false);
      setLoading(false); // Optional loader
      setShowCancelConfirmModal(false);
    }
  };

  const handleCancelInProgressClick = async (selectedCampaign) => {
    try {
      setIsSubmitting(true); // Set loading state
      setLoading(true); // Optional loader if you have one
      console.log("bchdsbchkbdshcbhj");
      const response = await axios.post(
        `${process.env.REACT_APP_WHATSAPP_API_URL}/api/whatsapp/cancel-campaign`,
        {
          campaignId: selectedCampaign.id,
        }
      );

      if (response.data.success) {
        // alert("Campaign cancelled successfully.");
        // Optionally, refresh the campaign list or UI
        setSnackbarMessage("Campaign Canceled successfully");
        setSnackbarVariant("toast-success");
        setShowSnackbar(true);
        dispatch(updateUserCredits(response?.data?.available_credits));
        dispatch(
          fetchEventDetail({
            offset: currentPage,
            limit: limit?.value,
            search: searchTerm,
            id: client_id,
            status: activeTab,
          })
        ); // Refresh the campaigns list
      }
    } catch (error) {
      setSnackbarMessage("Failed to cxcx cancel campaign");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
    } finally {
      setIsSubmitting(false);
      setLoading(false); // Optional loader
      setShowCancelConfirmModal(false);
    }
  };

  // const handlePauseInProgressClick = async (selectedCampaign) => {
  //   try {
  //     setIsSubmitting(true); // Set loading state
  //     setLoading(true); // Optional loader if you have one
  //     console.log("bchdsbchkbdshcbhj");
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_WHATSAPP_API_URL}/api/whatsapp/pause-campaign`,
  //       {
  //         campaignId: selectedCampaign.id,
  //       }
  //     );

  //     if (response.data.success) {
  //       // alert("Campaign cancelled successfully.");
  //       // Optionally, refresh the campaign list or UI
  //       setSnackbarMessage("Campaign Paused successfully");
  //       setSnackbarVariant("toast-success");
  //       setShowSnackbar(true);
  //       dispatch(
  //         fetchEventDetail({
  //           offset: currentPage,
  //           limit,
  //           search: searchTerm,
  //           id: client_id,
  //           status: activeTab,
  //         })
  //       ) // Refresh the campaigns list
  //     }
  //   } catch (error) {
  //     setSnackbarMessage("Failed to cxcx cancel campaign");
  //     setSnackbarVariant("toast-danger");
  //     setShowSnackbar(true);
  //   } finally {
  //     setIsSubmitting(false);
  //     setLoading(false); // Optional loader
  //     setShowCancelConfirmModal(false);
  //   }
  // };

  // const handleResumeInProgressClick = async (selectedCampaign) => {
  //   try {
  //     setIsSubmitting(true); // Set loading state
  //     setLoading(true); // Optional loader if you have one
  //     console.log("bchdsbchkbdshcbhj");
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_WHATSAPP_API_URL}/api/whatsapp/resume-campaign`,
  //       {
  //         campaignId: selectedCampaign.id,
  //       }
  //     );

  //     if (response.data.success) {
  //       // alert("Campaign cancelled successfully.");
  //       // Optionally, refresh the campaign list or UI
  //       setSnackbarMessage("Campaign Paused successfully");
  //       setSnackbarVariant("toast-success");
  //       setShowSnackbar(true);
  //       dispatch(
  //         fetchEventDetail({
  //           offset: currentPage,
  //           limit,
  //           search: searchTerm,
  //           id: client_id,
  //           status: activeTab,
  //         })
  //       ) // Refresh the campaigns list
  //     }
  //   } catch (error) {
  //     setSnackbarMessage("Failed to cxcx cancel campaign");
  //     setSnackbarVariant("toast-danger");
  //     setShowSnackbar(true);
  //   } finally {
  //     setIsSubmitting(false);
  //     setLoading(false); // Optional loader
  //     setShowCancelConfirmModal(false);
  //   }
  // };

  // const ActionMenu = ({ campaing, showSend }) => {
  //   return (
  //     <Dropdown drop="up" className="position-static">
  //       <Dropdown.Toggle as={CustomToggle}>
  //         <MoreVertical size="15px" className="text-muted" />
  //       </Dropdown.Toggle>
  //       <Dropdown.Menu align={"end"}>
  //         {(campaing.status === "pending" || campaing.status === "drafted") && (
  //           <Dropdown.Item
  //             eventKey="1"
  //             onClick={() => handleSendMessageClick(campaing.id)}
  //           >
  //             Send Message
  //           </Dropdown.Item>
  //         )}
  //         <Dropdown.Item
  //           eventKey="2"
  //           onClick={() => {
  //             navigate(`/customers/view-campaign/${campaing.id}`);
  //           }}
  //         >
  //           View
  //         </Dropdown.Item>
  //         {(campaing.status === "pending" ||
  //           campaing.status === "drafted" ||
  //           campaing.status === "scheduled") && (
  //           <Dropdown.Item
  //             eventKey="3"
  //             // onClick={() => {
  //             //   navigate(`/customers/view-campaign/${campaing.id}`);
  //             // }}
  //             onClick={() => handleDeleteCampaignClick(campaing)}
  //           >
  //             Delete
  //           </Dropdown.Item>
  //         )}
  //       </Dropdown.Menu>
  //     </Dropdown>
  //   );
  // };

  const getCampaignActions = (campaign) => {
    const actions = [];

    // Show "Send Message" action if campaign status is 'pending' or 'drafted'
    if (campaign.status === "pending" || campaign.status === "drafted") {
      actions.push({
        title: "Edit",
        handler: () => handleSendMessageClick(campaign.id),
      });
    }

    // if (campaign.status === "scheduled") {
    //   actions.push({
    //     title: "Send Now",
    //     // handler: () => handleCancelScheduleClick(campaign),
    //   });
    // }

    // Show "View" action for all campaigns
    actions.push({
      title: "View",
      handler: () => {
        navigate(`/customers/view-campaign/${campaign.id}`);
      },
    });

    // Show "Delete" action for 'pending', 'drafted', or 'scheduled' campaigns
    if (
      campaign.status === "pending" ||
      campaign.status === "drafted" ||
      campaign.status === "scheduled" ||
      campaign.status === "cancel"
    ) {
      actions.push({
        title: "Delete",
        handler: () => handleDeleteCampaignClick(campaign),
      });
    }

    if (campaign.status === "scheduled") {
      actions.push({
        title: "Cancel",
        handler: () => handleCancelScheduleClick(campaign),
      });
    }

    if (campaign.status === "in-process") {
      actions.push({
        title: "Cancel",
        handler: () => handleCancelInProgressClick(campaign),
      });
      // actions.push({
      //   title: "Pause",
      //   handler: () => handlePauseInProgressClick(campaign),
      // });
    }

    // if (campaign.status === "paused") {
    //   actions.push({
    //     title: "Resume",
    //     handler: () => handleResumeInProgressClick(campaign),
    //   });
    //   actions.push({
    //     title: "Cancel",
    //     handler: () => handleCancelInProgressClick(campaign),
    //   });
    // }

    return actions;
  };

  const handleDisconnectClick = () => {
    setShowDisconnectConfirmModal(true);
  };

  const handleConfirmDisconnect = async () => {
    try {
      setIsSubmitting(true);
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_WHATSAPP_API_URL}/api/whatsapp/disconnect`,
        { clientId: client_id }
      );
      if (response.data.success) {
        setSnackbarMessage("Disconnected successfully");
        setSnackbarVariant("toast-success");
        setShowSnackbar(true);
        getEventsList();
      } else {
        throw new Error(response.data.message || "Failed to disconnect");
      }
    } catch (error) {
      setSnackbarMessage(error.message);
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
    } finally {
      setLoading(false);
      setIsSubmitting(false);
      setShowDisconnectConfirmModal(false);
    }
  };

  const handleTabSelect = (key) => {
    setActiveTab(key);
    setCurrentPage(0);
    dispatch(clearCustomerCampaignList());
    setTableLoading(true);

     // ✅ Add a delay before fetching new data
    setTimeout(() => {
      setTableLoading(false);
    }, 5000); // 5-second delay
  };

  const getColumnsForActiveTab = () => {
    const commonColumns = [
      {
        label: "Name",
        dataKey: "name",
      },
      {
        label: "Send Type",
        dataKey: "template_type",
        format: (value) =>
          value === "pdf" || value === "image" ? "With file" : "With out file",
      },
      {
        label: "Status",
        dataKey: "status",
        format: (value) => (
          <StatusChip
            bg={
              value === "completed"
                ? "chip-green"
                : value === "in-process"
                ? "chip-blue"
                : value === "pending"
                ? "chip-yellow"
                : value === "drafted"
                ? "chip-blue"
                : value === "scheduled"
                ? "chip-ornage"
                : "chip-red"
            }
            title={capitalizeFirstLetter(value)}
          />
        ),
      },
    ];

    const dynamicColumns = [];

    switch (activeTab) {
      case "scheduled":
        dynamicColumns.push({
          label: "Scheduled Date",
          dataKey: "scheduler_date",
          format: (value) => moment(value).format("DD-MM-YYYY hh:mm A"),
        });
        break;
      case "completed":
        dynamicColumns.push({
          label: "From Number",
          dataKey: "from_number",
        });
        break;
      case "in-process":
        dynamicColumns.push({
          label: "From Number",
          dataKey: "from_number",
        });
        break;
      case "failed":
        dynamicColumns.push({
          label: "Failed Reason",
          dataKey: "failed_reason",
        });
        dynamicColumns.push({
          label: "From Number",
          dataKey: "from_number",
        });
        break;
      default:
        break;
    }

    const remainingColumns = [
      {
        label: "Credits Used",
        dataKey: "total_credits",
        render: (item) => {
          const totalCredits = item?.total_credits;
          const testCredits = item?.client_guests_test_count;
          const usedCredits = totalCredits - testCredits;
          return (
            <StatusChip
              pill
              bg={"chip-green"}
              title={usedCredits}
              className="text-capitalize"
            />
          );
        },
      },
      {
        label: "Test Credit",
        dataKey: "client_guests_test_count",
      },
      {
        label: "Created At",
        dataKey: "created_at",
        format: (value) => moment(value).format("DD-MM-YYYY hh:mm A"),
      },
    ];

    if (activeTab !== "pending" && activeTab !== "drafted") {
      remainingColumns.unshift({
        label: "Recipients",
        dataKey: "client_guests_not_test_count",
      });
    }

    return [...commonColumns, ...dynamicColumns, ...remainingColumns];
  };

  const handleEditClick = (customer) => {
    setShowEditModal(true); // Show the Edit modal
  };

  const handleEditClose = () => {
    getEventsList();
    setShowEditModal(false);
  };

  const getUserInitial = (name) => {
    return name
      ? name
          .split(" ")
          .map((n) => n.charAt(0))
          .join("")
      : "UN";
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const handleBackClick = () => {
    navigate("/customers", { replace: true });
    sessionStorage.setItem("currentCustomerPage",currentPgPassedInCustomerDetial);
    sessionStorage.setItem("currentCustomerPgLimit", JSON.stringify(currentLimitPassedInCustomerDetial));
  };

  const handleChange = (newValue) => {
    if (newValue) {
      // Update state with both value and label
      setLimit({ value: newValue.value, label: newValue.label });
      setCurrentPage(0);
    } else {
      // If the value is null (when user clears selection), set a default value
      setLimit({ value: 10, label: 10 }); // Set to default object
    }
  };

  // const [hoveredRow, setHoveredRow] = useState(null); // To track the hovered row
  const [selectedRows, setSelectedRows] = useState([]); // To track selected rows

  const handleCustomerDetailCampaign = (type, item) => {
    if (type === "all") {
      // Get the IDs of all rows on the current page
      const currentPageIds = campaings.map((row) => row.id);
      const allSelected = currentPageIds.every((id) =>
        selectedRows.some((row) => row.id === id)
      );

      if (allSelected) {
        // Deselect all rows on the current page
        setSelectedRows((prevSelected) =>
          prevSelected.filter((row) => !currentPageIds.includes(row.id))
        );
      } else {
        // Select all rows on the current page
        const newSelections = campaings.filter(
          (row) => !selectedRows.some((selected) => selected.id === row.id)
        );
        setSelectedRows((prevSelected) => [...prevSelected, ...newSelections]);
      }
    } else if (type === "single") {
      // Toggle selection for a single item
      const isSelected = selectedRows.some((row) => row.id === item.id);

      if (isSelected) {
        // Deselect the item
        setSelectedRows((prevSelected) =>
          prevSelected.filter((row) => row.id !== item.id)
        );
      } else {
        // Select the item
        setSelectedRows((prevSelected) => [...prevSelected, item]);
      }
    }
  };

  // Handle row selection
  // const handleRowSelection = (item) => {
  //   console.log("cbdb", item);
  //   setSelectedRows((prevSelected) =>
  //     prevSelected.includes(item)
  //       ? prevSelected.filter((rowId) => rowId.id !== item.id)
  //       : [...prevSelected, item]
  //   );
  // };

  // Check if a row is selected
  // const isRowSelected = (id) => selectedRows.includes(id);

  // Handle select all rows
  // const handleSelectAll = () => {
  //   if (selectedRows.length === campaings.length) {
  //     setSelectedRows([]); // Deselect all
  //   } else {
  //     setSelectedRows(campaings.map((item) => item)); // Select all
  //   }
  // };

  // Export campaigns' data as ZIP containing Excel files
  const handleExportAllCampaigns = async () => {
    if (!selectedRows.length) {
      setSnackbarMessage("Please select campaigns to export.");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
      return;
    }

    try {
      setLoading(true);
      const zip = new JSZip(); // Initialize ZIP file
      const campaignDetailsPromises = selectedRows.map(async (campaignId) => {
        const response = await dispatch(
          fetchAllCampaignDetail({
            id: campaignId.id, // Fetch data for the client
            // campaignId,
            limit: 100000, // fetch all the contacts of campaign
            is_test: "false",
          })
        ).unwrap();

        // console.log("Response :::: ", response,response?.campaingDetailsData?.data);

        const campaignData = response?.campaingDetailsData?.data?.map(
          (event) => ({
            Name: event?.name,
            Mobile: event?.mobile_number,
            CreatedAt: moment(event?.campaign.created_at).format(
              "DD/MM/YYYY hh:mm A"
            ),
            ScheduleDate: event?.campaign.scheduler_date
              ? moment(event?.campaign.scheduler_date).format(
                  "DD/MM/YYYY hh:mm A"
                )
              : "-",
            Status: event?.status,
            FailedReason: event?.failed_reason || "-",
            CreditsUsed: event?.credits,
            SentStatus: event?.whatsapp_sent
              ? moment.unix(event?.whatsapp_sent).format("DD/MM/YYYY hh:mm A")
              : "-",
            DeliveryStatus: event?.whatsapp_deliver
              ? moment
                  .unix(event?.whatsapp_deliver)
                  .format("DD/MM/YYYY hh:mm A")
              : "-",
            SeenStatus: event?.whatsapp_seen
              ? moment.unix(event?.whatsapp_seen).format("DD/MM/YYYY hh:mm A")
              : "-",
          })
        );

        // Convert campaign data to Excel
        const worksheet = XLSX.utils.json_to_sheet(campaignData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet);
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        // Add Excel file to ZIP
        zip.file(
          `Report_${campaings[0].client_event.name}_${campaignId.name}.xlsx`,
          excelBuffer
        );
      });

      // Wait for all campaign data to be fetched and processed
      await Promise.all(campaignDetailsPromises);

      // Generate ZIP file and trigger download
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, `${campaings[0].client_event.name}_${Date.now()}.zip`);

      setSnackbarMessage("Export successful!");
      setSnackbarVariant("toast-success");
      setShowSnackbar(true);
    } catch (error) {
      console.error("Error exporting campaigns:", error);
      setSnackbarMessage("Error exporting campaigns.");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
    } finally {
      setLoading(false);
      // Clear selection state
      setSelectedRows([]);
    }
  };

  // Open Delete Customer Modal
  // const deleteCustomerWithZeroCampaign = (item) => {
  //   setOpenDeleteCustomerModal(true);
  //   setCustomerId(item);
  // }

  // Final Deletion of Customer in customer detail
  // const handleConfirmDeleteCustomer = () => {
  //   try {
  //       setIsSubmitting(true); // Set loading state
  //       setLoading(true); // Optional loader if you have one
  //       dispatch(deleteCustomer({ id: customerId?.id }))
  //         setSnackbarMessage("Customer deleted successfully");
  //         setSnackbarVariant("toast-success");
  //         setShowSnackbar(true);
  //         navigate("/customers");
  //     } catch (error) {
  //       setSnackbarMessage("Failed to delete campaign");
  //       setSnackbarVariant("toast-danger");
  //       setShowSnackbar(true);
  //       setLoading(false);
  //     } finally {
  //       setIsSubmitting(false);
  //       setLoading(false); // Optional loader
  //       setOpenDeleteCustomerModal(false);
  //     }
  // }

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Container fluid className="px-0">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <BackPageBtnTab
            onClick={() => handleBackClick()}
            icon={
              <ArrowLeftIcon
                width={22}
                height={22}
                color="#444050"
                strokeWidth="2.2"
              />
            }
            title="Customer Details"
          />
          <div className="d-flex flex-row">
            {/* <button 
          className={`btn btn-danger mx-2`} 
          disabled={!customerDetailItem || customerDetailItem?.campaigns_count > 0}
          onClick={() => deleteCustomerWithZeroCampaign(customerDetailItem)}>
        Delete Customer
       </button> */}
          </div>
        </div>

        <Row className="mt-4">
          <Col xl={12} xxl={4}>
            <div className="d-flex align-content-start align-items-xxl-center flex-xxl-column gap-4 mb-4 mb-xxl-0">
              <Card className="rounded-2 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <div className="d-flex justify-content-between align-items-center gap-3">
                    <h5 className="fw-medium text-capitalize">
                      Personal Details
                    </h5>
                    <Link
                      to="#"
                      onClick={() => handleEditClick()}
                      className="text-capitalize fw-medium text-decoration-none"
                    >
                      Edit
                    </Link>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  <Link
                    to=""
                    className="d-flex align-items-center gap-2 text-decoration-none mb-4 card-profile"
                  >
                    <div className=" rounded-circle d-flex align-items-center justify-content-center flex-shrink-1 user-icon">
                      <span className="text-uppercase fw-normal">
                        {getUserInitial(event?.name)}
                      </span>
                    </div>
                    <h6 className="fw-medium ms-1 text-capitalize">
                      {event?.name}
                    </h6>
                  </Link>
                  <div className="d-flex flex-column gap-3 card-content mb-3 mb-xxl-4">
                    <div>
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Contact info
                      </h6>
                      <p className="mb-1 fw-normal">
                        Email: {event?.email || "-"}
                      </p>
                      <p className=" fw-normal">
                        Mobile: {event?.contact_number}
                      </p>
                    </div>
                    <div>
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Address info
                      </h6>
                      <p className="fw-normal">{event?.address || "-"}</p>
                    </div>
                  </div>
                  <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2 card-content">
                    <div className="g-col-12 g-col-xl-6">
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Created On
                      </h6>
                      <p className="fw-normal">
                        {moment(event?.created_at).format("DD/MM/YYYY hh:mm A")}
                      </p>
                    </div>
                    <div className="g-col-12 g-col-xl-6">
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Updated On
                      </h6>
                      <p className="fw-normal">
                        {moment(event?.updated_at).format("DD/MM/YYYY hh:mm A")}
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Card className="rounded-2 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <div>
                    <h5 className="fw-medium text-capitalize">
                      Campaigns Summary
                    </h5>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2">
                    <div className="g-col-12">
                      <div className=" d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center status-content warning-status">
                          <div className=" rounded-2 d-flex align-items-center justify-content-center status-icon">
                            <Megaphone01Icon
                              width={28}
                              height={28}
                              color="#28c76f"
                              strokeWidth="1.5"
                            />
                          </div>
                          <div>
                            <h6 className="fw-medium text-capitalize status-black">
                              Total Campaigns
                            </h6>
                          </div>
                        </div>
                        <span className="number-chip">
                          {event?.total_campaigns_count}
                        </span>
                      </div>
                    </div>
                    <div className="g-col-12 ">
                      <div className=" d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center status-content primary-status">
                          <div className=" rounded-2 d-flex align-items-center justify-content-center status-icon">
                            <UserIcon
                              width={28}
                              height={28}
                              color="#A5596A"
                              strokeWidth="1.5"
                            />
                          </div>
                          <div>
                            <h6 className="fw-medium text-capitalize">
                              Total Recipients
                            </h6>
                          </div>
                        </div>
                        <span className="number-chip">
                          {event?.total_recipients_count}
                        </span>
                      </div>
                    </div>
                    <div className="g-col-12">
                      <div className=" d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center status-content success-status">
                          <div className=" rounded-2 d-flex align-items-center justify-content-center status-icon">
                            <Coins01Icon
                              width={28}
                              height={28}
                              color="#28c76f"
                              strokeWidth="1.5"
                            />
                          </div>
                          <div>
                            <h6 className="fw-medium text-capitalize status-black">
                              Total Credits Used
                            </h6>
                          </div>
                        </div>
                        <span className="number-chip">
                          {event?.total_credits_count}
                        </span>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Card className="rounded-2 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <div>
                    <h5 className="fw-medium text-capitalize mb-1">
                      WhatsApp Details
                    </h5>
                    <p className="fw-medium">
                      <span>
                        {event?.whatsapp_client?.is_active
                          ? "Connected"
                          : "Disconnected"}{" "}
                        At :
                      </span>{" "}
                      {event?.whatsapp_client?.last_sync_at
                        ? moment
                            .unix(event?.whatsapp_client?.last_sync_at)
                            .format("DD/MM/YYYY hh:mm A")
                        : "-"}
                    </p>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2">
                    <div className="g-col-12 g-col-xl-6">
                      <div
                        className={`d-flex align-items-center status-content  ${
                          event?.whatsapp_client?.is_active
                            ? "success-status"
                            : "danger-status"
                        }`}
                      >
                        <div className=" rounded-2 d-flex align-items-center justify-content-center status-icon">
                          {event?.whatsapp_client?.is_active ? (
                            <WhatsappIcon
                              width={28}
                              height={28}
                              color="#28c76f"
                              strokeWidth="1.5"
                            />
                          ) : (
                            <RssErrorIcon
                              width={28}
                              height={28}
                              color="#ff4c51"
                              strokeWidth="1.5"
                            />
                          )}
                        </div>
                        <div>
                          <h6 className="fw-medium text-capitalize">Status</h6>
                          <span className="text-capitalize">
                            {event?.whatsapp_client?.is_active
                              ? "Connected"
                              : "Not Connected"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="g-col-12 g-col-xl-6">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className=" rounded-2 d-flex align-items-center justify-content-center status-icon">
                          <SmartPhone01Icon
                            width={28}
                            height={28}
                            color="#A5596A"
                            strokeWidth="1.5"
                          />
                        </div>
                        <div>
                          <h6 className="fw-medium text-capitalize">
                            Mobile No
                          </h6>
                          <span className="text-capitalize">
                            {event?.whatsapp_client?.is_active
                              ? event?.whatsapp_client?.mobile_number
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="g-col-12 common-mt-space">
                      <CommomButton
                        title={
                          !event?.whatsapp_client?.is_active
                            ? " Connect "
                            : "Disconnect"
                        }
                        onClick={
                          !event?.whatsapp_client?.is_active
                            ? () => handleOpenConnectModal()
                            : handleDisconnectClick
                        }
                        className="w-100 btn-primary primary-shadow "
                      />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
          <Col xl={12} xxl={8}>
            <div className="mb-4">
              <NavTabs
                defaultActiveKey="all"
                id="uncontrolled-tab-example"
                onSelect={handleTabSelect}
                tabsData={CampaignsDataTabs}
              />
            </div>
            <div className="bg-white rounded-2 overflow-hidden common-shadow">
              <div className="p-4 d-flex align-items-center justify-content-between gap-4">
                <div className="position-relative">
                  <Input
                    type="search"
                    value={searchTerm}
                    placeholder="Search Campaign Name"
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      setCurrentPage(0);
                    }}
                    className="shadow-none align-items-center justify-content-between base-search"
                  />
                  {searchTerm && (
                    <div>
                      <Cancel01Icon
                        height={14}
                        width={14}
                        color="#A5596A"
                        strokeWidth="3"
                        onClick={() => setSearchTerm("")}
                        className="cursor-pointer position-absolute top-50 translate-middle-y end-0 me-2"
                      />
                    </div>
                  )}
                </div>

                <div className="d-flex  gap-2">
                  {/* Button to trigger the export */}
                  <CommomButton
                    onClick={handleExportAllCampaigns}
                    title="Export"
                    className="btn-primary"
                    disabled={selectedRows.length === 0}
                  />
                  {/* Add Campaign */}
                  <CommomButton
                    onClick={() => handleAddClick()}
                    icon={<Add01Icon width={16} height={16} color="#ffffff" strokeWidth="2"/>}
                    title="New Campaign"
                    className="btn-primary common-one-shadow"
                  />
                </div>
              </div>
              <div className="table-responsive customer-details-tbl-list">
                <Table className="common-table">
                  <thead>
                    <tr className="position-sticky top-0 z-2">
                      <th className=" position-relative">
                        <div className="table-br h-100 position-absolute top-0">
                          <Checkbox
                            className="sm-checkbox d-flex align-items-center"
                            onChange={() =>
                              handleCustomerDetailCampaign("all", "item")
                            }
                            checked={
                              campaings.length > 0 &&
                              campaings.every((row) =>
                                selectedRows.some(
                                  (selected) => selected.id === row.id
                                )
                              )
                            }
                          />
                        </div>
                      </th>
                      {getColumnsForActiveTab().map((item, i) => {
                        return (
                          <th key={i}>
                            <div className="table-br">
                              <p>{item.label}</p>
                            </div>
                          </th>
                        );
                      })}
                      <th>
                        <div className="table-br">
                          <p>Actions</p>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  {tableLoading || customerDetailCampaignListLoader ?  <tr className="loader-tr-tbl"><td colSpan="9"><TableLoader /></td></tr> :
                    campaings.length === 0 ? (
                      <tr>
                        <td
                          className="text-center"
                          colSpan={getColumnsForActiveTab(activeTab).length + 2}
                        >
                          <div className="text-center w-100 blank-table-view">
                            <Image
                              src="/assets/images/component-common/blank-table.png"
                              alt="Blank-Table-Data-Image"
                              className="img-fluid"
                            />
                            <div className="mt-3">
                              <h5 className="fw-medium">
                                Couldn't find any data.
                              </h5>
                              <p className="pt-2 mt-1">
                                This report does not contain any points.
                                {/* Try to change your filter and try again. */}
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      campaings.map((item, i) => (
                        <tr
                          onClick={() =>
                            navigate(`/customers/view-campaign/${item.id}`, {
                              state: { item, limit, currentPage },
                            })
                          }
                          key={item.id}
                          // className={`data-row ${isRowSelected(item) ? "row-selected" : ""}`}
                          className={`data-row`}
                          // onMouseEnter={() => setHoveredRow(item.id)}
                          // onMouseLeave={() => setHoveredRow(null)}
                          // onClick={() => handleRowSelection(item)} // Row click selects it
                          style={{ cursor: "pointer" }}
                        >
                          <td onClick={(e) => e.stopPropagation()} >
                            <Checkbox
                              className="sm-checkbox d-flex align-items-center"
                              checked={selectedRows.some(
                                (row) => row.id === item.id
                              )}
                              onChange={() =>
                                handleCustomerDetailCampaign("single", item)
                              }
                            />
                          </td>
                          {getColumnsForActiveTab().map((column) => (
                            <td key={column.dataKey}>
                              {column.format
                                ? column.format(item[column.dataKey])
                                : column.render
                                ? column.render(item)
                                : item[column.dataKey]}
                            </td>
                          ))}
                          <td onClick={(e) => e.stopPropagation()}>
                            <CommonActionDropDown
                              as={ButtonGroup}
                              id="dropdown-basic"
                              icon={
                                <MoreVerticalIcon
                                  width={20}
                                  height={20}
                                  color="#6D6B77"
                                  strokeWidth="4"
                                />
                              }
                              actions={getCampaignActions(item)}
                              show={openDropdown === i}
                              onToggle={() =>
                                setOpenDropdown((prev) =>
                                  prev === i ? null : i
                                )
                              }
                              className="action-menu"
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>

              {/* <div className="custom-table-container">
                <div className="custom-table-header">
                  <div className="custom-table-row">
                    <div className="custom-table-cell">
                      <input
                        type="checkbox"
                        checked={selectedRows.length === campaings.length}
                        onChange={handleSelectAll}
                      />
                    </div>
                    {getColumnsForActiveTab().map((item, i) => (
                      <div className="custom-table-cell" key={i}>
                        <p>{item.label}</p>
                      </div>
                    ))}
                    <div className="custom-table-cell">
                      <p>Actions</p>
                    </div>
                  </div>
                </div>
                <div className="custom-table-body">
                  {campaings.length === 0 ? (
                    <div className="custom-table-row">
                      <div
                        className="custom-table-cell text-center"
                        colSpan={getColumnsForActiveTab(activeTab).length + 1}
                      >
                        <div className="text-center w-100 blank-table-view">
                          <Image
                            src="/assets/images/component-common/blank-table.png"
                            alt="Blank-Table-Data-Image"
                            className="img-fluid"
                          />
                          <div className="mt-3">
                            <h5 className="fw-medium">
                              Couldn't find any data.
                            </h5>
                            <p className="pt-2 mt-1">
                              This report does not contain any points. Try to
                              change your filter and try again.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    campaings.map((item, i) => (
                      <div
                        className={`custom-table-row ${
                          isRowSelected(item.id) ? "row-selected" : ""
                        }`}
                        key={item.id}
                        onMouseEnter={() => setHoveredRow(item.id)}
                        onMouseLeave={() => setHoveredRow(null)}
                        // onClick={() => handleRowSelection(item.id)}
                        onClick={() =>
                          navigate(`/customers/view-campaign/${item.id}`)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className="custom-table-cell">
                          {(hoveredRow === item.id ||
                            isRowSelected(item.id)) && (
                            <input
                              type="checkbox"
                              checked={isRowSelected(item.id)}
                              onChange={() => handleRowSelection(item.id)}
                              onClick={(e) => e.stopPropagation()}
                            />
                          )}
                        </div>
                        {getColumnsForActiveTab().map((column) => (
                          <div
                            className="custom-table-cell"
                            key={column.dataKey}
                          >
                            {column.format
                              ? column.format(item[column.dataKey])
                              : item[column.dataKey]}
                          </div>
                        ))}
                        <div
                          className="custom-table-cell"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <CommonActionDropDown
                            as={ButtonGroup}
                            id="dropdown-basic"
                            icon={
                              <MoreVerticalIcon
                                width={20}
                                height={20}
                                color="#6D6B77"
                                strokeWidth="4"
                              />
                            }
                            actions={getCampaignActions(item)}
                            show={openDropdown === i}
                            onToggle={() =>
                              setOpenDropdown((prev) => (prev === i ? null : i))
                            }
                            className="action-menu"
                          />
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div> */}

              <div className="common-table-foot-new d-flex flex-wrap align-items-center justify-content-between gap-4">
                <p className="custom-table-infotext ">
                  Displaying {currentPage * limit?.value + 1} to{" "}
                  {Math.min((currentPage + 1) * limit?.value, total)} of {total}{" "}
                  Campaigns
                </p>
                <div className="d-flex gap-4">
                  {/* {totalPages > 0 ? (
                    <> */}
                  {campaings?.length !== 0 && (
                    <>
                      <CreatableSelect
                        isClearable
                        options={recordsPerPageOptions}
                        value={limit}
                        onChange={handleChange}
                        placeholder="Select or create"
                        menuPortalTarget={document.body} // Render the menu at the body level
                        menuPlacement="top" // Display the options above the input
                        className="custom-pagination-visible-item"
                        styles={{
                          control: (base) => ({
                            ...base,
                            width: "170px", // Fixed width for the input field
                          }),
                          menu: (base) => ({
                            ...base,
                            width: "170px", // Fixed width for the dropdown menu
                          }),
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999, // Ensure it appears above everything
                          }),
                        }}
                      />
                      <CommonPagination
                        totalItems={total}
                        itemsPerPage={limit?.value}
                        currentPage={currentPage + 1}
                        onPageChange={handlePageChange}
                      />
                    </>
                  )}
                  {/* </>
                  ) : null} */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* New Campaign Modal */}
      <Modal show={addModal} onHide={toggleAddModal} centered>
        <ModalHeader closeButton>
          <h3 className="text-primary mb-0">
            {currentStep === 1 ? "Connect WhatsApp" : "New Campaign"}
          </h3>
        </ModalHeader>
        <ModalBody>
          {currentStep === 1 && (
            <div className="text-center">
              {qrCode ? (
                <>
                  <img src={qrCode} alt="WhatsApp QR Code" />
                  <p>Please open WhatsApp and scan the QR code</p>
                </>
              ) : (
                <p>Click the button below to connect to WhatsApp.</p>
              )}
            </div>
          )}
          {currentStep === 1 && (
            <div className="d-flex justify-content-center mt-3">
              <Button
                variant="primary"
                onClick={() => handleConnect()}
                disabled={isScanSuccessful || !!qrCode}
              >
                {qrCode ? "Connecting..." : "Connect"}
              </Button>
            </div>
          )}
          {currentStep === 2 && (
            <Formik
              initialValues={initialCampaignState}
              validationSchema={campaignValidation}
              onSubmit={async (
                values,
                { setSubmitting, resetForm, setErrors }
              ) => {
                setIsSubmitting(true);
                const formData = new FormData();
                formData.append("client_id", client_id);
                formData.append("_method", "POST");
                formData.append("name", values.name);

                // Conditionally append the template_type based on the selected send option
                if (values.sendOption === "message") {
                  formData.append("template_type", "text");
                }

                dispatch(addCampaign(formData))
                  .then((res) => {
                    if (res.payload.success) {
                      dispatch(
                        fetchEventDetail({
                          offset: currentPage,
                          limit: limit?.value,
                          id,
                        })
                      );
                      setSnackbarMessage(res.payload.message);
                      setSnackbarVariant("toast-success");
                      setShowSnackbar(true);
                      resetForm();
                      toggleAddModal();
                      navigate(
                        `/customers/${client_id}/add-campaign/${res.payload.data.id}`
                      );
                    } else {
                      setSnackbarMessage(res.payload.message);
                      setErrors(res.payload.data);
                      setSnackbarVariant("toast-danger");
                      setShowSnackbar(true);
                    }
                  })
                  .catch((error) => {
                    setSnackbarMessage(
                      "Failed to add campaign: " + error.message
                    );
                    setSnackbarVariant("toast-danger");
                    setShowSnackbar(true);
                  })
                  .finally(() => {
                    setIsSubmitting(false);
                  });
              }}
            >
              {({
                errors,
                touched,
                handleChange,
                // isSubmitting,
                submitForm,
                values,
              }) => (
                <>
                  <div className="d-flex gap-3 mb-3">
                    <FormCheck
                      type="radio"
                      label="Send with Document"
                      name="sendOption"
                      id="sendInvitation"
                      value="invitation"
                      checked={values.sendOption === "invitation"}
                      onChange={handleChange}
                      className={`${
                        errors.sendOption && touched.sendOption
                          ? "is-invalid"
                          : ""
                      } `}
                    />
                    <FormCheck
                      type="radio"
                      label="Only Message"
                      name="sendOption"
                      id="sendMessage"
                      value="message"
                      checked={values.sendOption === "message"}
                      onChange={handleChange}
                      className={`${
                        errors.sendOption && touched.sendOption
                          ? "is-invalid"
                          : ""
                      }`}
                    />

                    {errors.sendOption && touched.sendOption ? (
                      <div className="invalid-feedback">
                        {errors.sendOption}
                      </div>
                    ) : null}
                  </div>

                  <Field
                    name="name"
                    type="text"
                    className={`form-control ${
                      errors.name && touched.name ? "is-invalid" : ""
                    }`}
                    placeholder="Enter campaign name"
                    onChange={handleChange}
                  />
                  {errors.name && touched.name ? (
                    <div className="invalid-feedback">{errors.name}</div>
                  ) : null}
                  <br />
                  <ModalFooter className="d-flex justify-content-end">
                    <div className="d-flex align-items-center gap-3">
                      {currentStep === 2 && (
                        <Button
                          variant="primary"
                          onClick={submitForm}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Adding..." : "Add"}
                        </Button>
                      )}
                      <Button variant="secondary" onClick={toggleAddModal}>
                        Cancel
                      </Button>
                    </div>
                  </ModalFooter>
                </>
              )}
            </Formik>
          )}
        </ModalBody>
      </Modal>

      {/* Connect Modal */}
      <Modal
        show={showConnentModal}
        onHide={handleCloseClickConnectModal}
        centered
        dialogClassName="common-popup-dialog"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop"
      >
        <Modal.Body className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center  common-popup-colose-btn"
            onClick={handleCloseClickConnectModal}
          >
            <Cancel01Icon
              width={16}
              height={16}
              color="#BCBBC1"
              strokeWidth="2.5"
            />
          </div>
          <div className="mb-4 text-center common-popup-heading">
            <h4 className="text-capitalize pt-2 mt-1 fw-medium">
              Connect Device
            </h4>
            <p className="mt-3">Please open WhatsApp and scan the QR code</p>
          </div>
          <div className={qrCode ? null : "py-4"}>
            {qrCode ? (
              <div className="text-center">
                <Image
                  src={qrCode}
                  className="img-fluid"
                  alt="WhatsApp QR Code"
                />
              </div>
            ) : (
              <h6 className="text-center fw-medium">
                Click Connect to Start Scanning...
              </h6>
            )}
          </div>

          <div className="d-flex align-items-center justify-content-center mt-4 gap-3">
            <CommomButton
              onClick={handleCloseClickConnectModal}
              title="Close"
              className="btn-muted"
            />
            <CommomButton
              disabled={isScanSuccessful}
              onClick={() =>
                handleConnect(
                  campaings[0]?.client_event.whatsapp_client.mobile_number
                )
              }
              title="Connect"
              className="border-none rounded-2 text-decoration-none common-btn btn-primary primary-shadow btn btn-link"
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* Disconnect Modal */}
      <DeletePopup
        show={showDisconnectConfirmModal}
        onHide={() => setShowDisconnectConfirmModal(false)}
        icon={
          <Delete02Icon
            width={48}
            height={48}
            color="#ff4c51"
            strokeWidth="1.5"
          />
        }
        title="Confirm Disconnect"
        content="Are you sure you want to disconnect?"
        removeTitle="Cancel"
        onClickSave={handleConfirmDisconnect}
        saveTitle={isSubmitting ? "Disconnecting..." : "Disconnect"}
      />

      {/* Delete Customer */}
      {/* <DeletePopup
        show={openDeleteCustomerModal}
        onHide={() => setOpenDeleteCustomerModal(false)}
        icon={
          <Delete02Icon
            width={48}
            height={48}
            color="#ff4c51"
            strokeWidth="1.5"
          />
        }
        title="Confirm Delete?"
        content="Are you sure you want to delete this customer?"
        removeTitle="Cancel"
        onClickSave={handleConfirmDeleteCustomer}
        disabled={isSubmitting}
        saveTitle={isSubmitting ? "Deleting..." : "Delete"}
      /> */}

      {/* Delete Modal */}
      <DeletePopup
        show={showDeleteConfirmModal}
        onHide={() => setShowDeleteConfirmModal(false)}
        icon={
          <Delete02Icon
            width={48}
            height={48}
            color="#ff4c51"
            strokeWidth="1.5"
          />
        }
        title="Confirm Delete?"
        content="Are you sure you want to delete this campaign?"
        removeTitle="Cancel"
        onClickSave={handleConfirmDelete}
        saveTitle={isSubmitting ? "Deleting..." : "Delete"}
      />

      <DeletePopup
        show={showCancelConfirmModal}
        onHide={() => setShowCancelConfirmModal(false)}
        icon={
          <Delete02Icon
            width={48}
            height={48}
            color="#ff4c51"
            strokeWidth="1.5"
          />
        }
        title="Confirm Schedule Cancel?"
        content="Are you sure you want to Draft this campaign?"
        removeTitle="Cancel"
        onClickSave={handleConfirmCancel}
        saveTitle={isSubmitting ? "Drafting..." : "Draft"}
      />

      {showEditModal && (
        <Edit
          show={showEditModal}
          handleClose={() => handleEditClose()}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          searchTerm={searchTerm}
          limit={limit}
          id={id}
          currentCustomer={event} // Pass the current customer data to the Edit component
          setShowSnackbar={setShowSnackbar}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarVariant={setSnackbarVariant}
        />
      )}
    </>
  );
}
