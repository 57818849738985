import React, { forwardRef } from "react";
import { Form } from "react-bootstrap";

const Input = forwardRef(
  (
    {
      type,
      name,
      placeholder,
      value,
      id,
      size,
      onChange,
      isValid,
      className,
      accept,
      as,
      max,
      min,
      step,
      ariaLabel,
      ariaDescribedby,
      maxLength,
      autoComplete,
      style,
      minLength,
      onBlur,
      onSelect,
      disabled,
      tabIndex,
      autoFocus,
    },
    ref // ✅ Correctly pass ref
  ) => {
    return (
      <Form.Control
        as={as}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        id={id}
        size={size}
        onChange={onChange}
        isValid={isValid}
        accept={accept}
        ref={ref} // ✅ Attach ref properly
        max={max}
        min={min}
        step={step}
        aria-label={ariaLabel}
        aria-describedby={ariaDescribedby}
        minLength={minLength}
        maxLength={maxLength}
        autoComplete={autoComplete}
        style={style}
        onBlur={onBlur}
        onSelect={onSelect}
        className={`common-input ${className}`}
        disabled={disabled}
        tabIndex={tabIndex}
        autoFocus={autoFocus}
      />
    );
  }
);

// ✅ This ensures that `ref` works properly when used in parent components
export default Input;
