import React from 'react'
import { Calendar03Icon, Coins01Icon } from '../../../icons/icons'
import CommomButton from '../../../components/common-button'
import { Modal } from 'react-bootstrap'

const AlertPopup = ({showModal,daysLeft,dashboardData,handleClose}) => {
  return (
    <>
    <Modal
        show={showModal}
        // onHide={handleClose}
        centered
        dialogClassName="common-popup-dialog"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop"
      >
        <Modal.Body className="common-popup-body">
          <div className="text-center common-popup-heading">
            <div className="common-mb-space">
               {/* ✅ Icon Section */}
              <div className="d-flex  align-items-center justify-content-center gap-3">
              {daysLeft !== null && daysLeft <= 30 &&  <Calendar03Icon width={48} height={48} color="#ff4c51" strokeWidth="1.5"/>}
              {dashboardData?.available_credits < 100 && <Coins01Icon width={48} height={48} color="#ff4c51" strokeWidth="1.5"/> }
              </div>

              {/* ✅ Heading Section */}
              <h4 className="text-capitalize pt-2 mt-1 fw-medium">
                {daysLeft !== null && daysLeft <= 30 && "Account Expiration Soon!"}
                {dashboardData?.available_credits < 100 && "Low Credit Balance"}
              </h4>

              {/* ✅ Message Section */}
              {daysLeft !== null && daysLeft <= 30 &&  
                <p className="mt-3">
                  Your account will expire in just{" "}
                  <span className="custom-text-danger fw-medium">{daysLeft} days</span>{" "}
                  renew now to keep enjoying our services!
                </p>}
              {dashboardData?.available_credits < 100 &&  
                <p className="mt-3">
                  Your account is low on credit{" "}
                  <span className="custom-text-danger fw-medium">{dashboardData?.available_credits} </span>{" "}
                  recharge today <br/> to keep everything running smoothly.
                </p>}
            </div>

           {/* ✅ Button */}
            <div className="d-flex align-items-center justify-content-center pt-2 gap-3">
              <CommomButton type="button" onClick={handleClose} title="Ok" className="btn-primary" />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AlertPopup